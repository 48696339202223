import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';

import {PublicoRoutingModule} from './publico-routing.module';
import {SharedModule} from '../shared/shared.module';
import {NgxSpinnerModule} from 'ngx-spinner';
import {HTTP_INTERCEPTORS} from '@angular/common/http';
import {Interceptor} from '../interceptor';
import {FontAwesomeModule} from '@fortawesome/angular-fontawesome';
import {InicioComponent} from './inicio/inicio.component';
import {PublicoComponent} from './publico.component';
import {ContactoComponent} from './contacto/contacto.component';
import {AgmCoreModule} from '@agm/core';
import {ModelosProgramaticosComponent} from './modelos-programaticos/modelos-programaticos.component';
import {EventoPublicoComponent} from './evento-publico/evento-publico.component';
import {InstitucionComponent} from './institucion/institucion.component';
import {PatrocinioComponent} from './patrocinio/patrocinio.component';
import {CcComponent} from './cc/cc.component';
import {NspComponent} from './nsp/nsp.component';
import {MqmcComponent} from './mqmc/mqmc.component';
import {PactoComponent} from './pacto/pacto.component';
import {NoticiaPublicoComponent} from './noticia-publico/noticia-publico.component';
import {NgbCarouselModule, NgbModule, NgbTooltipModule} from '@ng-bootstrap/ng-bootstrap';
import {ReactiveFormsModule} from '@angular/forms';
import {InscripcionModalComponent} from '../shared/inscripcion-modal/inscripcion-modal.component';
import {CapacitacionPublicoComponent} from './capacitacion-publico/capacitacion-publico.component';
import { PveComponent } from './pve/pve.component';
import { EstructuraRrhhComponent } from './estructura-rrhh/estructura-rrhh.component';
import { ProyectosPublicoComponent } from './proyectos-publico/proyectos-publico.component';
import { NuestrosValoresComponent } from './nuestros-valores/nuestros-valores.component';
import { ConvocatoriaPublicoComponent } from './convocatoria-publico/convocatoria-publico.component';
import { EstructuraComiteComponent } from './estructura-comite/estructura-comite.component';
import { UefaComponent } from './uefa/uefa.component';
import { SembrandoIdeasComponent } from './sembrando-ideas/sembrando-ideas.component';
import { SemillasEquidadComponent } from './semillas-equidad/semillas-equidad.component';

@NgModule({
  declarations: [InicioComponent,
    PublicoComponent,
    ContactoComponent,
    ModelosProgramaticosComponent,
    EventoPublicoComponent,
    InstitucionComponent,
    PatrocinioComponent,
    CcComponent,
    NspComponent,
    MqmcComponent,
    PactoComponent,
    NoticiaPublicoComponent,
    CapacitacionPublicoComponent,
    PveComponent,
    EstructuraRrhhComponent,
    ProyectosPublicoComponent,
    NuestrosValoresComponent,
    ConvocatoriaPublicoComponent,
    EstructuraComiteComponent,
    UefaComponent,
    SembrandoIdeasComponent,
    SemillasEquidadComponent],
  entryComponents: [InscripcionModalComponent],
  imports: [
    CommonModule,
    PublicoRoutingModule,
    SharedModule,
    NgxSpinnerModule,
    FontAwesomeModule,
    AgmCoreModule,
    NgbCarouselModule,
    NgbTooltipModule,
    ReactiveFormsModule,
    NgbModule
  ], providers: [
    {
      provide: HTTP_INTERCEPTORS, useClass: Interceptor, multi: true
    }
  ]
})
export class PublicoModule {
}
