import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {environment} from '../../environments/environment.prod';

@Injectable({
  providedIn: 'root'
})
export class ComunicadoService {

  base = environment.base;

  constructor(private http: HttpClient) {
  }

  comunicadosPeriodo(data: any) {
    const params = new HttpParams()
      .set('gestion', data.gestion.toString())
      .set('mes', data.mes.toString())
      .set('valor', data.valor)
      .set('items', data.items.toString());
    return this.http.get(`${this.base}comunicados-periodo`, {
      params
    });
  }

  comunicadosEstadistico(data: any) {
    const params = new HttpParams()
      .set('gestion', data.gestion.toString())
      .set('mes', data.mes.toString());
    return this.http.get(`${this.base}comunicados-estadistico`, {
      params
    });
  }

  paginateURL(url, req) {
    // tslint:disable-next-line:max-line-length
    return this.http.get(`${url}&items=${req.items}&valor=${req.valor}&gestion=${req.gestion}&mes=${req.mes}`);
  }

  importacionBeneficiarios(data: any) {
    return this.http.post(`${this.base}comunicados-importacion-beneficiarios`, data);
  }
}
