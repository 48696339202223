import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {ToastrService} from 'ngx-toastr';
import {ActivatedRoute, Router} from '@angular/router';
import {environment} from '../../../../environments/environment.prod';
import {NgxSpinnerService} from 'ngx-spinner';
import {ActividadService} from '../../../services/actividad.service';

@Component({
  selector: 'app-actividad-edit',
  templateUrl: './actividad-edit.component.html',
  styleUrls: ['./actividad-edit.component.scss']
})
export class ActividadEditComponent implements OnInit {
  actividadId: number = null;
  actividad: any = null;
  actividadGroup: FormGroup;
  spinnerSettings = environment.spinnerSettings;

  constructor(private fb: FormBuilder,
              private toastr: ToastrService,
              private router: Router,
              private route: ActivatedRoute,
              private spinner: NgxSpinnerService,
              private actividadService: ActividadService) {
    this.spinner.show('spinner', this.spinnerSettings);
    this.route.params.subscribe((param: any) => {
      this.actividadId = param.id;
      this.actividadService.show(param.id)
        .subscribe((actividad: any) => {
          this.actividad = actividad;
          this.createForm();
          this.spinner.hide('spinner');
        });
    });
  }

  ngOnInit() {
  }

  createForm() {
    this.actividadGroup = this.fb.group({
      actividad_poa: new FormControl(this.actividad.actividad_poa, [Validators.required]),
      fecha_inicio: new FormControl(this.actividad.fecha_inicio, [Validators.required]),
      fecha_fin: new FormControl(this.actividad.fecha_fin, [Validators.required]),
      descripcion: new FormControl(this.actividad.descripcion),
      abierto: new FormControl(this.actividad.abierto),
      con_material: new FormControl(this.actividad.con_material),
      con_refrigerio: new FormControl(this.actividad.con_refrigerio),
      cc: new FormControl(this.actividad.cc),
      nsp: new FormControl(this.actividad.nsp),
      mqmc: new FormControl(this.actividad.mqmc),
      pacto: new FormControl(this.actividad.pacto),
      patrocinio: new FormControl(this.actividad.patrocinio)
    });
  }

  update() {
    this.spinner.show('spinner', this.spinnerSettings);
    this.actividadService.update(this.actividadGroup.value, this.actividadId)
      .subscribe((res: any) => {
        this.spinner.hide('spinner');
        this.toastr.success('Actividad actualizada', 'Éxito');
        this.router.navigate(['/admin/actividades']);
      }, (error: any) => {
        this.spinner.hide('spinner');
        this.toastr.error('Ocurrio un error al actualizar la información', 'Intente nuevamente');
      });
  }

}
