import {Component, OnInit} from '@angular/core';
import {
  faBackward,
  faCheck,
  faEdit,
  faFastBackward,
  faFastForward,
  faForward,
  faPlus,
  faSearch,
  faTimes,
  faTrash,
  faUpload,
  faUserFriends,
  faUserPlus,
  faFile
} from '@fortawesome/free-solid-svg-icons';
import {BeneficiarioService} from '../beneficiario.service';
import {NgxSpinnerService} from 'ngx-spinner';
import {AuthService} from '../../../auth.service';
import {environment} from '../../../../environments/environment.prod';
import {faFileExcel} from '@fortawesome/free-solid-svg-icons/faFileExcel';
import {Zona} from '../../../models/zona';
import {FormControl} from '@angular/forms';
import {ToastrService} from 'ngx-toastr';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {RevisionService} from '../revision.service';
import {RequestAfiliado} from '../../../models/request-afiliado';
import {ZonaService} from '../../../services/zona.service';

@Component({
  selector: 'app-beneficiario-index',
  templateUrl: './beneficiario-index.component.html',
  styleUrls: ['./beneficiario-index.component.scss']
})
export class BeneficiarioIndexComponent implements OnInit {

  faPlus = faPlus;
  faUserPlus = faUserPlus;
  faUserFriends = faUserFriends;
  faFile = faFile;
  faUpload = faUpload;
  faEdit = faEdit;
  faTrash = faTrash;
  faSearch = faSearch;
  faFileExcel = faFileExcel;
  faCheck = faCheck;
  faTimes = faTimes;

  color = '#C60917';
  /*icons of pagination*/
  faBackward = faBackward;
  faFastBackward = faFastBackward;
  faForward = faForward;
  faFastForward = faFastForward;

  beneficiarios: any = null;
  zonas: Zona[];
  zonaIdCtrl: FormControl;
  modeloProgramatico: any = 'patrocinio';
  items = 10;
  valor = '';
  usuario: any = null;
  spinnerSettings = environment.spinnerSettings;
  base = environment.base;

  constructor(private beneficiarioService: BeneficiarioService,
              private zonaService: ZonaService,
              private revisionService: RevisionService,
              private modalService: NgbModal,
              private toastr: ToastrService,
              private authService: AuthService,
              private spinner: NgxSpinnerService) {
    environment.spinnerSettings.color = '#C60917ee';
    this.spinner.show('spinner', this.spinnerSettings);
    const req = new RequestAfiliado();
    beneficiarioService.buscarAfiliados(req)
      .subscribe((res: any) => {
        this.beneficiarios = res;
        this.spinner.hide('spinner');
      });
  }

  ngOnInit() {
    this.spinner.show('spinner', this.spinnerSettings);
    this.authService.getUser()
      .subscribe((res: any) => {
        this.usuario = res.success;
      });
    this.zonaIdCtrl = new FormControl(0);
    this.zonaService.index()
      .subscribe((res: Zona[]) => {
        res.unshift(new Zona('Todos', 'T', null, null, null, 0));
        this.zonas = res;
        this.spinner.hide('spinner');
      });
  }

  paginateURL(url: string) {
    this.spinner.show('spinner', this.spinnerSettings);
    const req = new RequestAfiliado();
    req.items = this.items;
    req.zona_id = this.zonaIdCtrl.value;
    req.modelo_programatico = this.modeloProgramatico;
    req.valor = this.valor;
    req.es_comunitario = 0;

    this.beneficiarioService.paginateURL(url, req)
      .subscribe((res: any) => {
        this.beneficiarios = res;
        this.spinner.hide('spinner');
      });
  }


  buscarAfiliados() {
    this.spinner.show('spinner', this.spinnerSettings);
    const req = new RequestAfiliado();
    req.items = this.items;
    req.zona_id = this.zonaIdCtrl.value;
    req.modelo_programatico = this.modeloProgramatico;
    req.valor = this.valor;
    req.es_comunitario = 0;

    this.beneficiarioService.buscarAfiliados(req)
      .subscribe((res: any) => {
        this.beneficiarios = res;
        this.spinner.hide('spinner');
      });
  }

  setColor(programa: string) {
    switch (programa) {
      case 'publico':
        this.color = '#16874F';
        environment.spinnerSettings.color = '#16874F';
        break;
      case 'patrocinio':
        this.color = '#C60917';
        environment.spinnerSettings.color = '#C60917';
        break;
      case 'cc':
        this.color = '#FF9000';
        environment.spinnerSettings.color = '#FF9000';
        break;
      case 'mqmc':
        this.color = '#87BF36';
        environment.spinnerSettings.color = '#87BF36';
        break;
      case 'nsp':
        this.color = '#6E2673';
        environment.spinnerSettings.color = '#6E2673';
        break;
      case 'pacto':
        this.color = '#207BBC';
        environment.spinnerSettings.color = '#207BBC';
        break;
    }
  }

  setModeloProgramatico(items: number, modeloProgramatico: 'cc' | 'nsp' | 'mqmc' | 'pacto' | 'patrocinio') {
    this.setColor(modeloProgramatico);
    this.modeloProgramatico = modeloProgramatico;
    this.buscarAfiliados();
  }

  export() {
    this.spinner.show('spinner', this.spinnerSettings);
    this.beneficiarioService.beneficiariosExport()
      .subscribe((res: any) => {
        const data = [];
        let i = 0;
        res.forEach((record: any) => {
          data.push({
            n: ++i,
            cuenta: record.cuenta,
            zona: record.nombre_zona,
            community_id: record.community_id,
            numero_caso: record.numero_caso,
            nombre_completo: record.nombre_completo,
            genero: record.genero,
            fecha_nacimiento: record.fecha_nacimiento,
            edad: record.edad,
            celular_afiliado: record.celular_afiliado,
            ci_afiliado: record.ci_afiliado,
            direccion: record.direccion,
            referencia_direccion: record.referencia_direccion,
            descripcion_casa: record.descripcion_casa,
            padre: record.padre,
            celular_padre: record.celular_padre,
            ci_padre: record.ci_padre,
            madre: record.madre,
            celular_madre: record.celular_madre,
            ci_madre: record.ci_madre,
            referencia_familiar: record.referencia_familiar,
            numero_referencia_familiar: record.numero_referencia_familiar,
            nombre_tutor: record.nombre_tutor,
            celular_tutor: record.celular_tutor,
            whatsapp_familia: record.whatsapp_familia,
            grado_estudio: record.grado_estudio,
            unidad_educativa_universidad: record.unidad_educativa_universidad,
            curso_grado: record.curso_grado,
            paralelo: record.paralelo,
            latitud: record.latitud,
            longitud: record.longitud,
            numero_hijos: record.numero_hijos,
            numero_hijos_afiliados: record.numero_hijos_afiliados,
            estado_patrocinio: record.estado_patrocinio,
            nombre_patrocinador: record.nombre_patrocinador,
            contacto_patrocinador: record.contacto_patrocinador,
            pais: record.pais,
            fecha_enrolamiento: record.fecha_enrolamiento,
            fecha_registro: record.fecha_registro
          });
        });
        setTimeout(() => {
          this.spinner.hide('spinner');
        }, 1000);
        this.beneficiarioService.exportAsExcelFile(data, 'beneficiarios');
      });
  }
}
