import { Injectable } from '@angular/core';
import {environment} from '../../../environments/environment.prod';
import {HttpClient, HttpRequest} from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ComunitarioService {

    base = environment.base;
    constructor(private http: HttpClient) { }

    index() {
        return this.http.get(`${this.base}beneficiarios`);
    }

    paginate(items: number) {
        return this.http.get(`${this.base}beneficiarios-paginate/${items}`);
    }
    store(data: any) {
        return this.http.post(`${this.base}beneficiarios`, data);
    }
    paginateTipoComunitario(items: number, tipoComunitario: string) {
        return this.http.get(`${this.base}beneficiarios-paginate-tipo-beneficiarios/${items}/${tipoComunitario}`);
    }
    paginateTipoComunitarioBusqueda(data) {
        return this.http.post(`${this.base}beneficiarios-paginate-tipo-comunitario-busqueda`, data);
    }
    paginateURL(url) {
        return this.http.get(url);
    }

    update(id, data: any) {
        return this.http.put(`${this.base}beneficiarios/${id}`, data);
    }
    show(id) {
        return this.http.get(`${this.base}beneficiarios/${id}`);
    }

}
