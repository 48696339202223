import {Component, OnInit, SecurityContext, ViewChild} from '@angular/core';
import {NoticiaService} from '../noticia.service';
import {NgxSpinnerService} from 'ngx-spinner';
import {ActivatedRoute} from '@angular/router';
import {
    faCalendarAlt,
    faFile,
    faFileArchive,
    faHome,
    faImage,
    faMailBulk,
    faPlay,
    faTimes
} from '@fortawesome/free-solid-svg-icons';
import {environment} from '../../../../environments/environment.prod';
import {faYoutube} from '@fortawesome/free-brands-svg-icons';


@Component({
  selector: 'app-noticia-show',
  templateUrl: './noticia-show.component.html',
  styleUrls: ['./noticia-show.component.scss']
})
export class NoticiaShowComponent implements OnInit {
    noticia = null;
    faImage = faImage;
    faFile = faFile;
    faYoutube = faYoutube;
    faHome = faHome;
    faCalendarAlt = faCalendarAlt;
    color = environment.spinnerSettings.color;

    spinnerSettings = environment.spinnerSettings;
    imagenSettings = null;
    archivoSettings = null;
    youtubeSettings = null;

    multimediaSettings = null;
    constructor(private noticiaService: NoticiaService,
                private spinner: NgxSpinnerService,
                private route: ActivatedRoute) {
    }

    ngOnInit() {
        this.spinner.show('spinner', this.spinnerSettings);
        this.route.params.subscribe((param: any) => {
            this.noticiaService.show(param.id)
                .subscribe((noticia: any) => {
                    this.noticia = noticia;
                    this.imagenSettings = {
                        publicacion_id: noticia.noticia_id,
                        tipo_publicacion: 'noticia',
                        tipo_multimedia: 'imagen',
                        acciones: true
                    };
                    this.archivoSettings = {
                        publicacion_id: noticia.noticia_id,
                        tipo_publicacion: 'noticia',
                        tipo_multimedia: 'archivo',
                        acciones: true
                    };
                    this.youtubeSettings = {
                        publicacion_id: noticia.noticia_id,
                        tipo_publicacion: 'noticia',
                        tipo_multimedia: 'youtube',
                        acciones: true
                    };
                    this.spinner.hide('spinner');
                });
        });
    }

    createMultimedia(tipoMultimedia: string) {
        this.multimediaSettings = {
            publicacion_id: this.noticia.noticia_id,
            publicacion: this.noticia.titulo,
            tipo_publicacion: 'noticia',
            tipo_multimedia: tipoMultimedia
        };
    }
}
