import { Component, OnInit } from '@angular/core';
import {EventoService} from '../evento.service';
import {ActivatedRoute} from '@angular/router';
import {faCalendarAlt, faFile, faHome, faImage, faPlay} from '@fortawesome/free-solid-svg-icons';
import {environment} from '../../../../environments/environment.prod';
import {NgxSpinnerService} from 'ngx-spinner';
import {faYoutube} from '@fortawesome/free-brands-svg-icons';

@Component({
  selector: 'app-evento-show',
  templateUrl: './evento-show.component.html',
  styleUrls: ['./evento-show.component.scss']
})
export class EventoShowComponent implements OnInit {

  evento = null;
  faImage = faImage;
  faFile = faFile;
  faYoutube = faYoutube;
  faHome = faHome;
  faCalendarAlt = faCalendarAlt;
  color = environment.spinnerSettings.bdColor;

  spinnerSettings = environment.spinnerSettings;
  horarioSettings = null;
  imagenSettings = null;
  archivoSettings = null;
  youtubeSettings = null;

  multimediaSettings = null;
  constructor(private eventoService: EventoService,
              private spinner: NgxSpinnerService,
              private route: ActivatedRoute) {
  }

  ngOnInit() {
    this.spinner.show('spinner', this.spinnerSettings);
    this.route.params.subscribe((param: any) => {
      this.eventoService.show(param.id)
        .subscribe((evento: any) => {
          this.evento = evento;
          this.horarioSettings = {
            evento_id: evento.evento_id,
            acciones: true
          };
          this.imagenSettings = {
            publicacion_id: evento.evento_id,
            tipo_publicacion: 'evento',
            tipo_multimedia: 'imagen',
            acciones: true
          };
          this.archivoSettings = {
            publicacion_id: evento.evento_id,
            tipo_publicacion: 'evento',
            tipo_multimedia: 'archivo',
            acciones: true
          };
          this.youtubeSettings = {
            publicacion_id: evento.evento_id,
            tipo_publicacion: 'evento',
            tipo_multimedia: 'youtube',
            acciones: true
          };
          this.spinner.hide('spinner');
        });
    });
  }

  createMultimedia(tipoMultimedia: string) {
    this.multimediaSettings = {
      publicacion_id: this.evento.evento_id,
      publicacion: this.evento.titulo,
      tipo_publicacion: 'evento',
      tipo_multimedia: tipoMultimedia
    };
  }
}
