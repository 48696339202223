import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {faHome, faImage, faMailBulk, faPlay} from '@fortawesome/free-solid-svg-icons';
import {environment} from '../../../environments/environment.prod';

@Component({
  selector: 'app-noticia-content',
  templateUrl: './noticia-content.component.html',
  styleUrls: ['./noticia-content.component.scss']
})
export class NoticiaContentComponent implements OnInit {

  @Input() noticia: any;
  @Output() back = new EventEmitter<string>();
  base = environment.base;
  faPlay = faPlay;
  faImage = faImage;
  faMailBulk = faMailBulk;
  faHome = faHome;
  galleryBackground = false;
  imagen = null;
  constructor() { }

  ngOnInit() {
    window.scrollTo(0, 0);
  }

  setImagen(multimediaId: number) {
    this.galleryBackground = true;
    this.imagen = this.base + 'multimedia-download/' + multimediaId;
  }
  goBack() {
    this.back.emit('noticias');
  }
}
