import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {ComunitarioService} from '../comunitario.service';
import {ToastrService} from 'ngx-toastr';
import {Router} from '@angular/router';

@Component({
  selector: 'app-comunitario-create',
  templateUrl: './comunitario-create.component.html',
  styleUrls: ['./comunitario-create.component.scss']
})
export class ComunitarioCreateComponent implements OnInit {

  comunitarioGroup: FormGroup;

  constructor(private fb: FormBuilder,
              private toastr: ToastrService,
              private router: Router,
              private comunitarioService: ComunitarioService) {
    this.createForm();
  }

  ngOnInit() {
  }

  createForm() {
    this.comunitarioGroup = this.fb.group({
      child_number: new FormControl('', [Validators.required]),
      nombre_completo: new FormControl('', [Validators.required]),
      fecha_nacimiento: new FormControl('', [Validators.required]),
      sexo: new FormControl('Male', [Validators.required]),
      nombre_tutor: new FormControl(''),
      numero_referencia_familiar: new FormControl(''),
      celular_tutor: new FormControl(''),
      celular_afiliado: new FormControl(''),
      comunitario: new FormControl(true),
      direccion: new FormControl(''),
      unidad_educativa_universidad: new FormControl(''),
      curso_grado: new FormControl('')
    });
  }

  store() {
    this.comunitarioService.store(this.comunitarioGroup.value)
      .subscribe((res: any) => {
        if (res.error) {
          this.toastr.error(res.error, 'Registro duplicado');
        } else {
          this.router.navigate(['/admin/comunitarios/listar']);
          this.toastr.success(`${this.comunitarioGroup.value.nombre_completo} registrado`, 'Registro exitoso');
        }
      });
  }
}
