import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'app-noticia-item',
  templateUrl: './noticia-item.component.html',
  styleUrls: ['./noticia-item.component.scss']
})
export class NoticiaItemComponent implements OnInit {

  @Input() noticia: any;
  @Output() selected = new EventEmitter<any>();
  constructor() { }

  ngOnInit() {
  }

  getNoticia() {
    this.selected.emit(this.noticia);
  }

}
