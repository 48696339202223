import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Youtube} from '../youtube';
import {faPlay} from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-search-result',
  templateUrl: './search-result.component.html',
  styleUrls: ['./search-result.component.scss']
})
export class SearchResultComponent implements OnInit {

  @Input() result: Youtube;
  @Output() url = new EventEmitter<string>();
  faPlay = faPlay;

  constructor() { }

  ngOnInit() {
  }
  setYoutubeVideo(video: any) {
    this.url.emit(video);
  }

}
