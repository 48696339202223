import { Component, OnInit } from '@angular/core';
import { faWhatsapp} from '@fortawesome/free-brands-svg-icons';
@Component({
  selector: 'app-estructura-rrhh',
  templateUrl: './estructura-rrhh.component.html',
  styleUrls: ['./estructura-rrhh.component.scss']
})
export class EstructuraRrhhComponent implements OnInit {
  faWhatsapp = faWhatsapp;

  constructor() { }

  ngOnInit() {
  }

}
