import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {ToastrService} from 'ngx-toastr';
import {Router} from '@angular/router';
import {AuthService} from '../../../auth.service';
import {ActividadPoaService} from '../../../services/actividad-poa.service';
import {ActividadService} from '../../../services/actividad.service';

@Component({
  selector: 'app-actividad-create',
  templateUrl: './actividad-create.component.html',
  styleUrls: ['./actividad-create.component.scss']
})
export class ActividadCreateComponent implements OnInit {
  actividadGroup: FormGroup;
  actividadesPOA: any[] = [];

  constructor(private fb: FormBuilder,
              private toastr: ToastrService,
              private router: Router,
              private actividadPOAService: ActividadPoaService,
              private actividadService: ActividadService,
              private authService: AuthService) {
    this.createForm();
  }

  ngOnInit() {
    this.actividadPOAService.index()
      .subscribe((res: any[]) => {
        this.actividadesPOA = res;
      });
  }

  createForm() {
    this.actividadGroup = this.fb.group({
      actividad_poa: new FormControl('', [Validators.required]),
      fecha_inicio: new FormControl('', [Validators.required]),
      fecha_fin: new FormControl('', [Validators.required]),
      descripcion: new FormControl(''),
      abierto: new FormControl(false),
      con_material: new FormControl(false),
      con_refrigerio: new FormControl(false),
      cc: new FormControl(false),
      nsp: new FormControl(false),
      mqmc: new FormControl(false),
      pacto: new FormControl(false),
      patrocinio: new FormControl(false)
    });
  }

  store() {
    this.actividadService.store(this.actividadGroup.value)
      .subscribe((res: any) => {
        this.toastr.success(`${res.titulo} registrada exitosamente`, 'Actividad registrada');
        this.router.navigate(['/admin/actividades/listar']);
      });
  }
}
