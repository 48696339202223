import {Injectable} from '@angular/core';
import {environment} from '../../../environments/environment.prod';
import {HttpClient, HttpParams, HttpRequest} from '@angular/common/http';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import {RequestAfiliado} from '../../models/request-afiliado';

const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx';

@Injectable({
  providedIn: 'root'
})
export class BeneficiarioService {

  base = environment.base;

  constructor(private http: HttpClient) {
  }


  public exportAsExcelFile(json: any, excelFileName: string): void {
    const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json);
    const workbook: XLSX.WorkBook = {
      Sheets: {
        datos: ws,
      },
      SheetNames: ['datos']
    };
    const excelBuffer: any = XLSX.write(workbook, {bookType: 'xlsx', type: 'array'});
    this.saveAsExcelFile(excelBuffer, excelFileName);
  }

  index() {
    return this.http.get(`${this.base}beneficiarios`);
  }

  paginate(items: number) {
    return this.http.get(`${this.base}beneficiarios-paginate/${items}`);
  }

  show(id) {
    return this.http.get(`${this.base}beneficiarios/${id}`);
  }

  store(data: any) {
    return this.http.post(`${this.base}beneficiarios`, data);
  }

  update(id, data: any) {
    return this.http.put(`${this.base}beneficiarios/${id}`, data);
  }

  destroy(id) {
    return this.http.delete(`${this.base}beneficiarios/${id}`);
  }

  importarBeneficiarios(data: any) {
    const request = new HttpRequest('POST', `${this.base}importar-beneficiarios`, data, {
      reportProgress: true
    });
    return this.http.request(request);
  }

  paginateTipoBeneficiario(items: number, tipoBeneficiario: string) {
    return this.http.get(`${this.base}beneficiarios-paginate-tipo-beneficiario/${items}/${tipoBeneficiario}`);
  }

  paginateTipoBeneficiarioBusqueda(data) {
    return this.http.post(`${this.base}beneficiarios-paginate-tipo-beneficiario-busqueda`, data);
  }

  paginateTipoComunitario(items: number, tipoBeneficiario: string) {
    return this.http.get(`${this.base}comunitarios-paginate-tipo-comunitario/${items}/${tipoBeneficiario}`);
  }

  paginateTipoComunitarioBusqueda(data) {
    return this.http.post(`${this.base}comunitarios-paginate-tipo-comunitario-busqueda`, data);
  }

  paginateURL(url, req) {
    // tslint:disable-next-line:max-line-length
    return this.http.get(`${url}&items=${req.items}&zona_id=${req.zona_id}&modelo_programatico=${req.modelo_programatico}&valor=${req.valor}&es_comunitario=${req.es_comunitario}`);
  }

  beneficiariosExport() {
    return this.http.get(`${this.base}beneficiarios-export`);
  }

  comunitariosExport() {
    return this.http.get(`${this.base}comunitarios-export`);
  }

  sociosLocales() {
    return this.http.get(`${this.base}socios-locales`);
  }

  buscarAfiliados(req: RequestAfiliado) {
    const params = new HttpParams()
      .set('items', req.items.toString())
      .set('zona_id', req.zona_id.toString())
      .set('modelo_programatico', req.modelo_programatico)
      .set('valor', req.valor)
      .set('es_comunitario', req.es_comunitario.toString())
      .set('page', req.page.toString());
    return this.http.get(`${this.base}buscar-afiliados`, {
      params
    });
  }

  /**
   * FIN DE NUEVAS APIS
   * **/

  /*
  * SOCIOS LOCALES
  * */

  beneficiariosSocioLocal(socio_local_id: number, items: number = 10, page: number = 1) {
    const params = new HttpParams()
      .set('socio_local_id', socio_local_id.toString())
      .set('items', items.toString())
      .set('page', page.toString());
    return this.http.get(`${this.base}beneficiarios-socio-local`, {
      params
    });
  }

  beneficiariosTipoBeneficiarioSocioLocal(items: number, socio_local_id: number, tipo_beneficiario: string, page: number = 1) {
    const params = new HttpParams()
      .set('items', items.toString())
      .set('socio_local_id', socio_local_id.toString())
      .set('tipo_beneficiario', tipo_beneficiario.toString())
      .set('page', page.toString());
    return this.http.get(`${this.base}paginate-tipo-beneficiario-socio-local`, {
      params
    });
  }

  beneficiariosTipoBeneficiarioSocioLocalBusqueda(items: number, socio_local_id: number, tipo_beneficiario: string, valor: string, page: number = 1) {
    const params = new HttpParams()
      .set('items', items.toString())
      .set('socio_local_id', socio_local_id.toString())
      .set('tipo_beneficiario', tipo_beneficiario.toString())
      .set('valor', valor.toString())
      .set('page', page.toString());
    return this.http.get(`${this.base}paginate-tipo-beneficiario-socio-local-busqueda`, {
      params
    });
  }

  comunitariosTipoBeneficiarioSocioLocal(items: number, socio_local_id: number, tipo_beneficiario: string, page: number = 1) {
    const params = new HttpParams()
      .set('items', items.toString())
      .set('socio_local_id', socio_local_id.toString())
      .set('tipo_beneficiario', tipo_beneficiario.toString())
      .set('page', page.toString());
    return this.http.get(`${this.base}paginate-tipo-comunitario-socio-local`, {
      params
    });
  }

  public aprobarBeneficiario(beneficiario_id: number) {
    return this.http.put(`${this.base}aprobar-beneficiario/${beneficiario_id}`, null);
  }

  public observarBeneficiario(beneficiario_id: number) {
    return this.http.put(`${this.base}observar-beneficiario/${beneficiario_id}`, null);
  }

  public listadoBeneficiarios() {
    return this.http.get(`${this.base}listado-beneficiarios`);
  }

  public recuperarBeneficiarios(child_numbers: any[]) {
    return this.http.post(`${this.base}recuperar-beneficiarios`, child_numbers);
  }

  private saveAsExcelFile(buffer: any, fileName: string): void {
    const data: Blob = new Blob([buffer], {type: EXCEL_TYPE});
    FileSaver.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
  }

}
