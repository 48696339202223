import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {AdminComponent} from './admin.component';
import {BeneficiarioComponent} from './beneficiario/beneficiario.component';
import {BeneficiarioIndexComponent} from './beneficiario/beneficiario-index/beneficiario-index.component';
import {BeneficiarioUploadComponent} from './beneficiario/beneficiario-upload/beneficiario-upload.component';
import {BeneficiarioCreateComponent} from './beneficiario/beneficiario-create/beneficiario-create.component';
import {PersonalComponent} from './personal/personal.component';
import {PersonalIndexComponent} from './personal/personal-index/personal-index.component';
import {PersonalCreateComponent} from './personal/personal-create/personal-create.component';
import {PersonalEditComponent} from './personal/personal-edit/personal-edit.component';
import {NoticiaComponent} from './noticia/noticia.component';
import {NoticiaIndexComponent} from './noticia/noticia-index/noticia-index.component';
import {NoticiaCreateComponent} from './noticia/noticia-create/noticia-create.component';
import {NoticiaEditComponent} from './noticia/noticia-edit/noticia-edit.component';
import {NoticiaShowComponent} from './noticia/noticia-show/noticia-show.component';
import {EventoComponent} from './evento/evento.component';
import {EventoIndexComponent} from './evento/evento-index/evento-index.component';
import {EventoCreateComponent} from './evento/evento-create/evento-create.component';
import {EventoEditComponent} from './evento/evento-edit/evento-edit.component';
import {EventoShowComponent} from './evento/evento-show/evento-show.component';
import {HorarioCreateComponent} from '../shared/horario-create/horario-create.component';
import {DocumentacionComponent} from './documentacion/documentacion.component';
import {DocumentacionIndexComponent} from './documentacion/documentacion-index/documentacion-index.component';
import {DocumentacionCreateComponent} from './documentacion/documentacion-create/documentacion-create.component';
import {DocumentacionEditComponent} from './documentacion/documentacion-edit/documentacion-edit.component';
import {DocumentacionShowComponent} from './documentacion/documentacion-show/documentacion-show.component';
import {TestCreateComponent} from '../shared/test-create/test-create.component';
import {TestStatsComponent} from '../shared/test-stats/test-stats.component';
import {ComunitarioComponent} from './comunitario/comunitario.component';
import {ComunitarioIndexComponent} from './comunitario/comunitario-index/comunitario-index.component';
import {ComunitarioCreateComponent} from './comunitario/comunitario-create/comunitario-create.component';
import {BeneficiarioEditComponent} from './beneficiario/beneficiario-edit/beneficiario-edit.component';
import {ComunitarioEditComponent} from './comunitario/comunitario-edit/comunitario-edit.component';
import {CapacitacionComponent} from './capacitacion/capacitacion.component';
import {CapacitacionIndexComponent} from './capacitacion/capacitacion-index/capacitacion-index.component';
import {CapacitacionCreateComponent} from './capacitacion/capacitacion-create/capacitacion-create.component';
import {CorrespondenciaComponent} from './correspondencia/correspondencia.component';
import {CorrespondenciaIndexComponent} from './correspondencia/correspondencia-index/correspondencia-index.component';
import {CorrespondenciaImportComponent} from './correspondencia/correspondencia-import/correspondencia-import.component';
import {ComunicadoComponent} from './comunicado/comunicado.component';
import {ComunicadoCreateComponent} from './comunicado/comunicado-create/comunicado-create.component';
import {ZonaComponent} from './zona/zona.component';
import {ZonaIndexComponent} from './zona/zona-index/zona-index.component';
import {ZonaEditComponent} from './zona/zona-edit/zona-edit.component';
import {ZonaCreateComponent} from './zona/zona-create/zona-create.component';
import {ActividadComponent} from './actividad/actividad.component';
import {ActividadIndexComponent} from './actividad/actividad-index/actividad-index.component';
import {ActividadCreateComponent} from './actividad/actividad-create/actividad-create.component';
import {ActividadEditComponent} from './actividad/actividad-edit/actividad-edit.component';
import {ComunicadoIndexComponent} from './comunicado/comunicado-index/comunicado-index.component';

const routes: Routes = [
  {
    path: '',
    component: AdminComponent,
    children: [
      {
        path: 'beneficiarios',
        component: BeneficiarioComponent,
        children: [
          {
            path: 'upload',
            component: BeneficiarioUploadComponent
          },
          {
            path: 'listar',
            component: BeneficiarioIndexComponent
          },
          {
            path: 'crear',
            component: BeneficiarioCreateComponent
          },
          {
            path: 'editar/:id',
            component: BeneficiarioEditComponent
          },
          {
            path: '',
            redirectTo: 'listar',
            pathMatch: 'full'
          }
        ]
      },
      {
        path: 'comunitarios',
        component: ComunitarioComponent,
        children: [
          {
            path: 'listar',
            component: ComunitarioIndexComponent
          },
          {
            path: 'crear',
            component: ComunitarioCreateComponent
          },
          {
            path: 'editar/:id',
            component: ComunitarioEditComponent
          },
          {
            path: '',
            redirectTo: 'listar',
            pathMatch: 'full'
          }
        ]
      },
      {
        path: 'personal',
        component: PersonalComponent,
        children: [
          {
            path: 'listar',
            component: PersonalIndexComponent
          },
          {
            path: 'crear',
            component: PersonalCreateComponent
          },
          {
            path: 'editar/:id',
            component: PersonalEditComponent
          },
          {
            path: '',
            redirectTo: 'listar',
            pathMatch: 'full'
          }
        ]
      },
      {
        path: 'zonas',
        component: ZonaComponent,
        children: [
          {
            path: 'listar',
            component: ZonaIndexComponent
          },
          {
            path: 'crear',
            component: ZonaCreateComponent
          },
          {
            path: 'editar/:id',
            component: ZonaEditComponent
          },
          {
            path: '',
            redirectTo: 'listar',
            pathMatch: 'full'
          }
        ]
      },
      {
        path: 'noticias',
        component: NoticiaComponent,
        children: [
          {
            path: 'listar',
            component: NoticiaIndexComponent
          },
          {
            path: 'mostrar/:id',
            component: NoticiaShowComponent
          },
          {
            path: 'crear',
            component: NoticiaCreateComponent
          },
          {
            path: 'editar/:id',
            component: NoticiaEditComponent
          },
          {
            path: '',
            redirectTo: 'listar',
            pathMatch: 'full'
          }
        ]
      },
      {
        path: 'eventos',
        component: EventoComponent,
        children: [
          {
            path: 'listar',
            component: EventoIndexComponent
          },
          {
            path: 'crear',
            component: EventoCreateComponent
          },
          {
            path: 'editar/:id',
            component: EventoEditComponent
          },
          {
            path: 'mostrar/:id',
            component: EventoShowComponent
          },
          {
            path: '',
            redirectTo: 'listar',
            pathMatch: 'full'
          }
        ]
      },
      {
        path: 'aulas',
        component: DocumentacionComponent,
        children: [
          {
            path: 'listar',
            component: DocumentacionIndexComponent
          },
          {
            path: 'crear',
            component: DocumentacionCreateComponent
          },
          {
            path: 'editar/:id',
            component: DocumentacionEditComponent
          },
          {
            path: 'mostrar/:id',
            component: DocumentacionShowComponent
          },
          {
            path: 'crear-test',
            component: TestCreateComponent
          },
          {
            path: 'stats/:test_id',
            component: TestStatsComponent
          },
          {
            path: '',
            redirectTo: 'listar',
            pathMatch: 'full'
          }
        ]
      },
      {
        path: 'capacitaciones',
        component: CapacitacionComponent,
        children: [
          {
            path: 'listar',
            component: CapacitacionIndexComponent
          },
          {
            path: 'crear',
            component: CapacitacionCreateComponent
          },
          {
            path: '',
            redirectTo: 'listar',
            pathMatch: 'full'
          }
        ]
      },
      {
        path: 'correspondencia',
        component: CorrespondenciaComponent,
        children: [
          {
            path: 'listar',
            component: CorrespondenciaIndexComponent
          },
          {
            path: 'importacion',
            component: CorrespondenciaImportComponent
          },
          {
            path: '',
            redirectTo: 'listar',
            pathMatch: 'full'
          }
        ]
      },
      {
        path: 'actividades',
        component: ActividadComponent,
        children: [
          {
            path: 'listar',
            component: ActividadIndexComponent
          },
          {
            path: 'crear',
            component: ActividadCreateComponent
          },
          {
            path: 'editar/:id',
            component: ActividadEditComponent
          },
          {
            path: '',
            redirectTo: 'listar',
            pathMatch: 'full'
          }
        ]
      },
      {
        path: 'comunicacion',
        component: ComunicadoComponent,
        children: [
          {
            path: 'crear',
            component: ComunicadoCreateComponent
          },
          {
            path: 'listar',
            component: ComunicadoIndexComponent
          },
          {
            path: '',
            redirectTo: 'listar',
            pathMatch: 'full'
          }
        ]
      },
      {
        path: 'crear-horario/:evento_id',
        component: HorarioCreateComponent
      },
      {
        path: '',
        redirectTo: 'beneficiarios',
        pathMatch: 'full'
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AdminRoutingModule {
}
