import {Component, Input, OnInit} from '@angular/core';
import {TestService} from '../test.service';
import {ActivatedRoute} from '@angular/router';

@Component({
  selector: 'app-test-stats',
  templateUrl: './test-stats.component.html',
  styleUrls: ['./test-stats.component.scss']
})
export class TestStatsComponent implements OnInit {

  chart = {
    columnNames: [
      'Respuesta', 'Total'
    ],
    type: 'PieChart'
  };

  test: any = null;

  constructor(private testService: TestService,
              private route: ActivatedRoute) { }

  ngOnInit() {
    this.route.params.subscribe((param: any) => {
      this.testService.stats(param.test_id)
        .subscribe((res: any) => {
          this.test = res;
        });
    });
  }

}
