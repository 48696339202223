import {Component, Input, OnInit} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {Correspondencia} from '../../models/correspondencia';
import {CorrespondenciaService} from '../../services/correspondencia.service';
import {FormControl, Validators} from '@angular/forms';
import {DomSanitizer} from '@angular/platform-browser';

@Component({
  selector: 'app-revision-modal',
  templateUrl: './revision-modal.component.html',
  styleUrls: ['./revision-modal.component.scss']
})
export class RevisionModalComponent implements OnInit {

  @Input() correspondencia: Correspondencia;
  pdf: any = null;
  estadoSupervisorControl: FormControl = new FormControl(null, [Validators.required]);

  constructor(public activeModal: NgbActiveModal,
              private sanitizer: DomSanitizer,
              public correspondenciaService: CorrespondenciaService) {
  }

  ngOnInit() {
    this.pdf = this.sanitizer.bypassSecurityTrustResourceUrl(this.correspondencia.correspondencia_escaneada);
  }

  /**
   * Cambiar estado de supervisor
   */

  cambiarEstado() {
    const estado = this.estadoSupervisorControl.value.replace(/[_]/, ' ');
    if (confirm(`¿Esta seguro de cambiar el estado a ${estado}?`)) {
      this.correspondenciaService.cambiarEstado(this.estadoSupervisorControl.value, this.correspondencia.correspondencia_id)
        .subscribe((correspondencia: Correspondencia) => {
          this.activeModal.close();
        });
    }
  }

}
