import {Component, Input, OnInit} from '@angular/core';
import {faChartPie, faCheck, faEdit, faFileExcel, faTimes, faTrash} from '@fortawesome/free-solid-svg-icons';
import {ToastrService} from 'ngx-toastr';
import {TestService} from '../test.service';

@Component({
  selector: 'app-test-index',
  templateUrl: './test-index.component.html',
  styleUrls: ['./test-index.component.scss']
})
export class TestIndexComponent implements OnInit {

  @Input() settings: any;
  tests: any = null;

  faEdit = faEdit;
  faTrash = faTrash;
  faChartPie = faChartPie;
  faCheck = faCheck;
  faTimes = faTimes;
  faFileExcel = faFileExcel;

  constructor(private testService: TestService,
              private toastr: ToastrService) { }
  ngOnInit() {
    this.testService.get(this.settings.documentacion_en_linea_id)
      .subscribe((res: any) => {
        this.tests = res;
      });
  }

  reload() {
    this.testService.get(this.settings.documentacion_en_linea_id)
      .subscribe((res: any) => {
        this.tests = res;
      });
  }
  destroy(testId: number) {
    if (confirm('¿Esta seguro de eliminar el test?')) {
      this.testService.destroy(testId)
        .subscribe((res: any) => {
          this.reload();
          this.toastr.success('Test eliminado', 'Éxito');
        });
    }
  }

  exportExcel(testId: number) {
    this.testService.respuestasTest(testId)
      .subscribe((res: any) => {
        const test = res;
        this.testService.exportAsExcelFile(test.data, test.test);
      });
  }
}
