import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {environment} from '../../../environments/environment.prod';
import {NoticiaService} from '../../admin/noticia/noticia.service';
import { faEye, faEyeSlash, faPlay, faImage, faHome, faMailBulk } from '@fortawesome/free-solid-svg-icons';
import * as $ from 'jquery';

@Component({
  selector: 'app-convocatoria-publico',
  templateUrl: './convocatoria-publico.component.html',
  styleUrls: ['./convocatoria-publico.component.scss']
})
export class ConvocatoriaPublicoComponent implements OnInit {

  @Input() noticia: any;
  @Output() back = new EventEmitter<string>();
  noticias = null;
  faEye = faEye;
  faEyeSlash = faEyeSlash;
  base = environment.base;
  faPlay = faPlay;
  faImage = faImage;
  faHome = faHome;
  galleryBackground = false;
  imagen = null;
  faMailBulk = faMailBulk;

  constructor(private noticiaService: NoticiaService) {
    this.noticiaService.ultimasConvocatorias()
      .subscribe((res: any) => {
        const noticias: any[] = res;
        this.noticias = noticias.map((item: any) => {
          return {
            ...item,
            show: false
          };
        });
      });
  }

  ngOnInit() {
    $(document).ready(() => {
      $('html, body').animate({
        scrollTop: 0
      }, 'fast');
    });
  }
  setImagen(multimediaURL: string) {
    this.galleryBackground = true;
    this.imagen =  multimediaURL;
  }
  goBack() {
    this.back.emit('convocatorias');
  }
}
