import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {NoticiaService} from '../../admin/noticia/noticia.service';
import {faCalendarAlt, faEye, faEyeSlash, faPlay, faImage, faMailBulk, faHome} from '@fortawesome/free-solid-svg-icons';
import * as $ from 'jquery';
import {environment} from '../../../environments/environment.prod';

@Component({
  selector: 'app-noticia-publico',
  templateUrl: './noticia-publico.component.html',
  styleUrls: ['./noticia-publico.component.scss']
})
export class NoticiaPublicoComponent implements OnInit {
  @Input() noticia: any;
  @Output() back = new EventEmitter<string>();
  noticias = null;
  faCalendarAlt = faCalendarAlt;
  faEye = faEye;
  faEyeSlash = faEyeSlash;
  base = environment.base;
  faPlay = faPlay;
  faImage = faImage;
  faMailBulk = faMailBulk;
  faHome = faHome;
  galleryBackground = false;
  imagen = null;

  constructor(private noticiaService: NoticiaService) {
    this.noticiaService.publico()
      .subscribe((res: any) => {
        const noticias: any[] = res;
        this.noticias = noticias.map((item: any) => {
          return {
            ...item,
            show: false
          };
        });
      });
  }

  ngOnInit() {
    $(document).ready(() => {
      $('html, body').animate({
        scrollTop: 0
      }, 'fast');
    });
  }
  setImagen(multimediaURL: string) {
    this.galleryBackground = true;
    this.imagen =  multimediaURL;
  }
  goBack() {
    this.back.emit('noticias');
  }

}
