export class ChartConfig {
  constructor(
    public type: 'bar',
    public data: ChartData,
    public options: any = {
      indexAxis: 'y',
      scales: {
        y: {
          beginAtZero: true
        }
      },
      responsive: true
    }
  ) {

  }
}

interface ChartData {
  labels: string[];
  datasets: ChartDataset[];
}

interface ChartDataset {
  axis: 'x' | 'y';
  label: string;
  data: number[];
  backgroundColor: string[];
  borderColor: string[];
  borderWidth: number;
}

