import {Injectable} from '@angular/core';
import {environment} from '../../../environments/environment.prod';
import {HttpClient, HttpParams} from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class NoticiaService {

  base = environment.base;

  constructor(private http: HttpClient) {
  }

  paginateURL(url: string) {
    return this.http.get(url);
  }

  sendNotification(data: any) {
    return this.http.post('https://fcm.googleapis.com/fcm/send', data, {
      headers: {
        Authorization: 'key=AAAA2JMeyPE:APA91bEkvtmnM24x0AUjvkQLsCRYAQJ29MdpWGcGP_41ny8y3cKSUAvVcYwIAHHzgmAEop5lkPyxPfSKu1RhrESvn_J4njMAbrmK8-LA78W-nHixUbK9RCHLblw_jwS7zKwNZJ5kN4wM'
      }
    });
  }

  programa(data: any) {
    return this.http.post(`${this.base}noticias-programa`, data);
  }

  beneficiario(usuarioId: number) {
    return this.http.get(`${this.base}noticias-beneficiario/${usuarioId}`);
  }

  search(data: any) {
    return this.http.post(`${this.base}noticias-search`, data);
  }

  publico() {
    return this.http.get(`${this.base}noticias-publico`);
  }

  show(noticiaId: number) {
    return this.http.get(`${this.base}noticias/${noticiaId}`);
  }

  store(data: any) {
    return this.http.post(`${this.base}noticias`, data);
  }

  update(data: any, noticiaId: number) {
    return this.http.put(`${this.base}noticias/${noticiaId}`, data);
  }

  destroy(noticiaId: number) {
    return this.http.delete(`${this.base}noticias/${noticiaId}`);
  }

  ultimasNoticias(tipoNoticia: string = 'TODOS') {
    const params = new HttpParams()
      .set('tipo_noticia', tipoNoticia);
    return this.http.get(`${this.base}ultimas-noticias`, {
      params
    });
  }
  ultimasConvocatorias(tipoNoticia: string = 'TODOS') {
    const params = new HttpParams()
      .set('tipo_noticia', tipoNoticia);
    return this.http.get(`${this.base}ultimas-convocatorias`, {
      params
    });
  }
}
