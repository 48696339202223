import { Injectable } from '@angular/core';
import {environment} from '../../environments/environment.prod';
import {HttpClient} from '@angular/common/http';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx';

@Injectable({
  providedIn: 'root'
})
export class HorarioService {

  base = environment.base;
  constructor(private http: HttpClient) { }

  public exportAsExcelFile(json: any, excelFileName: string): void {
    const eventoHorarioWS: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json.evento_horario);
    const beneficiariosWS: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json.data);
    const workbook: XLSX.WorkBook = {
      Sheets: {
        beneficiarios: beneficiariosWS,
        evento: eventoHorarioWS,
      },
      SheetNames: ['beneficiarios', 'evento']
    };
    const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    this.saveAsExcelFile(excelBuffer, excelFileName);
  }
  private saveAsExcelFile(buffer: any, fileName: string): void {
    const data: Blob = new Blob([buffer], {type: EXCEL_TYPE});
    FileSaver.saveAs(data, fileName + '_export_' + new  Date().getTime() + EXCEL_EXTENSION);
  }

  get(eventoId: number) {
    return this.http.get(`${this.base}horarios-get/${eventoId}`);
  }

  beneficiarios(horarioId: number) {
    return this.http.get(`${this.base}beneficiarios-horario/${horarioId}`);
  }

  store(data: any) {
    return this.http.post(`${this.base}horarios`, data);
  }

  destroy(horarioId: number) {
    return this.http.delete(`${this.base}horarios/${horarioId}`);
  }
}
