import {Component, OnInit, ViewChild} from '@angular/core';
import {CorrespondenciaService} from '../../../services/correspondencia.service';
import {TipoCorrespondencia} from '../../../models/tipo-correspondencia';
import {TipoCorrespondenciaService} from '../../../services/tipo-correspondencia.service';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import * as XLSX from 'xlsx';
import {HttpEventType, HttpResponse} from '@angular/common/http';
import {ToastrService} from 'ngx-toastr';
import {Router} from '@angular/router';
import {ComunicadoService} from '../../../services/comunicado.service';
import { faDownload } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-correspondencia-import',
  templateUrl: './correspondencia-import.component.html',
  styleUrls: ['./correspondencia-import.component.scss']
})
export class CorrespondenciaImportComponent implements OnInit {

  @ViewChild('excel', {static: false}) excel: any;

  faDownload = faDownload;

  correspondenciaGroup: FormGroup;
  tipoCorrespondencias: TipoCorrespondencia[] = [];
  loading = false;
  nuevasCorrespondencias = 0;

  counter = 0;
  timer = 0;
  refreshInterval: any = null;
  percentage = 0;

  constructor(
    private toastr: ToastrService,
    private router: Router,
    private fb: FormBuilder,
    private comunicadoService: ComunicadoService,
    private tipoCorrespondenciaService: TipoCorrespondenciaService,
    private correspondenciaService: CorrespondenciaService) {
  }

  static excelToDate(excelDate: number) {
    const dateInfo = new Date((excelDate - (25567 + 1)) * 86400 * 1000);
    const gestion = dateInfo.getFullYear();
    const mes = (dateInfo.getMonth() + 1) < 10 ? '0' + (dateInfo.getMonth() + 1) : (dateInfo.getMonth() + 1);
    const dia = (dateInfo.getDate() < 10) ? '0' + dateInfo.getDate() : dateInfo.getDate();
    return gestion + '-' + mes + '-' + dia;
  }

  ngOnInit() {
    this.correspondenciaGroup = this.fb.group({
      tipo_correspondencia_id: new FormControl(0, [Validators.required]),
      fecha_recepcion: new FormControl(null, [Validators.required]),
      fecha_limite_entrega: new FormControl(null, [Validators.required]),
      descripcion: new FormControl(null, [Validators.required]),
      cantidad: new FormControl(null),
      excel: new FormControl(null, [Validators.required])
    });
    this.tipoCorrespondenciaService.index()
      .subscribe((response: TipoCorrespondencia[]) => {
        this.tipoCorrespondencias = response;
      });
  }

  upload() {
    const file = this.excel.nativeElement.files[0];
    if (file) {
      if (file.type !== 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' &&
        file.type !== 'application/vnd.ms-excel') {
        this.toastr.error('El archivo no es una hoja de cálculo', 'Excel no encontrado');
      } else {
        this.loading = true;
        const reader: FileReader = new FileReader();
        reader.onload = (e: any) => {
          /*
          * Read workbook
          * */
          const bstr = e.target.result;
          const wb: XLSX.WorkBook = XLSX.read(bstr, {
            type: 'binary'
          });

          /*
          * Grab first sheet
          * */
          const wsname: string = wb.SheetNames[0];
          const ws: XLSX.WorkSheet = wb.Sheets[wsname];

          /*
          * Save data
          * */
          const data: any = XLSX.utils.sheet_to_json(ws, {
            header: 1
          });

          let info = '[';
          this.nuevasCorrespondencias = data.length - 1;
          for (let i = 1; i < data.length; i++) {
            info += '{';
            for (let j = 0; j < data[0].length; j++) {
              const key = data[0][j].toLowerCase().replace(/ /gi, '_');
              if (key === 'mcs_date') {
                info += '"' + key + '":"' + CorrespondenciaImportComponent.excelToDate(parseInt(data[i][j], 10)) + '"';
              } else {
                if (data[i][j] === undefined || data[i][j] === 'undefined' || data[i][j] === null || data[i][j] === '') {
                  info += `"${key}":null`;
                } else {
                  const limpio = data[i][j].toString().replace(/(\r\n|\n|\r|"|')/gm, '');
                  info += `"${key}":"${limpio}"`;
                }
              }
              if (j !== (data[0].length - 1)) {
                info += ',';
              }
            }
            info += '}';
            if (i !== data.length - 1) {
              info += ',';
            }
          }
          info += ']';

          const datosImportacion = JSON.parse(info);
          console.log(datosImportacion);
          const correspondencia = {
            contenido: `Tiene correspondencia por recoger, planilla ${this.correspondenciaGroup.get('descripcion').value}`,
            con_sms: true,
            tipo: 'correspondencia',
            lista: []
          };

          datosImportacion.map((beneficiario: any) => {
            correspondencia.lista.push({
              child_number: beneficiario.child_number
            });
          });
          this.comunicadoService.importacionBeneficiarios(correspondencia)
            .subscribe((res: any) => {
              console.log(res);
            });

          const seconds = 0.015 * datosImportacion.length;
          this.counter = 0;
          this.timer = 0;
          this.refreshInterval = setInterval(() => {
            this.counter = Math.round(this.timer * 100 / seconds);
            this.timer++;
            if (this.counter >= 100) {
              clearInterval(this.refreshInterval);
            }
          }, 1000);

          this.correspondenciaService.store(datosImportacion,
            this.correspondenciaGroup.get('tipo_correspondencia_id').value,
            this.correspondenciaGroup.get('descripcion').value,
            this.correspondenciaGroup.get('fecha_recepcion').value,
            this.correspondenciaGroup.get('fecha_limite_entrega').value,
          )
            .subscribe((res: any) => {
              if (res.type === HttpEventType.UploadProgress) {
                console.log('subiendo');
                console.log(Math.round(100 * res.loaded / res.total));
                this.percentage = Math.round(100 * res.loaded / res.total);
              } else if (res instanceof HttpResponse) {
                this.loading = false;
                this.router.navigate(['/admin/correspondencia/listar']);
                this.toastr.success(`${res.body.lote}, ${res.body.message}`, 'Importacion exitosa');
              }
            }, () => {
              this.toastr.error('Algunos campos no fueron llenados correctamente', 'Excel mal formado');
              this.loading = false;
            });
        };
        reader.readAsBinaryString(file);
      }
    }
  }

  generarDescripcion() {
    console.log(this.correspondenciaGroup.get('fecha_recepcion').value);
    const dia_inicio = this.correspondenciaGroup.get('fecha_recepcion').value.split('-')[2];
    const dia_fin = this.correspondenciaGroup.get('fecha_limite_entrega').value.split('-')[2];
    const mes_fin = this.toLiteralMonth(Number(this.correspondenciaGroup.get('fecha_limite_entrega').value.split('-')[1]) - 1);
    const descripcion = `_${dia_inicio}_${dia_fin}_${mes_fin}`;
    switch (Number(this.correspondenciaGroup.get('tipo_correspondencia_id').value)) {
      case 1:
        this.correspondenciaGroup.patchValue({
          descripcion: `WELCOME${descripcion}`
        });
        break;
      case 2:
        this.correspondenciaGroup.patchValue({
          descripcion: `SPONSOR-REPLY${descripcion}`
        });
        break;
      case 3:
        this.correspondenciaGroup.patchValue({
          descripcion: `PLANILLA-DFC_${this.correspondenciaGroup.get('cantidad').value}_${mes_fin}`
        });
        break;
      case 4:
        this.correspondenciaGroup.patchValue({
          descripcion: `INICIADAS${descripcion}`
        });
        break;
      case 5:
        this.correspondenciaGroup.patchValue({
          descripcion: `ESPONTANEAS_${mes_fin}`
        });
        break;
    }
  }

  private toLiteralMonth(month: number) {
    const months = ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio',
      'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'];
    return months[month].toUpperCase();
  }
}
