import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {MultimediaService} from '../multimedia.service';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {environment} from '../../../environments/environment.prod';
import {NgxSpinnerService} from 'ngx-spinner';
import {Youtube} from '../youtube';

@Component({
  selector: 'app-multimedia-create',
  templateUrl: './multimedia-create.component.html',
  styleUrls: ['./multimedia-create.component.scss']
})
export class MultimediaCreateComponent implements OnInit {

  @Input() multimediaSettings: any;
  @Output() switch = new EventEmitter<any>();
  @ViewChild('file', {static: false}) file: any;

  color = environment.spinnerSettings.bdColor;
  multimediaGroup: FormGroup;
  spinnerSettings = environment.spinnerSettings;

  results: Youtube[] = null;
  loading = false;
  message = '';
  youtubeSearch = false;
  constructor(private multimediaService: MultimediaService,
              private spinner: NgxSpinnerService,
              private fb: FormBuilder) { }

  ngOnInit() {
    this.multimediaGroup = this.fb.group({
      publicacion_id: new FormControl(this.multimediaSettings.publicacion_id, [Validators.required]),
      tipo_publicacion: new FormControl(this.multimediaSettings.tipo_publicacion, [Validators.required]),
      tipo_multimedia: new FormControl(this.multimediaSettings.tipo_multimedia, [Validators.required]),
      publicacion: new FormControl(this.multimediaSettings.publicacion),
      titulo: new FormControl(''),
    });
  }
  store() {
    if (this.file.nativeElement.files[0]) {
      this.spinner.show('spinner', this.spinnerSettings);
      const formData = new FormData();
      const files = this.file.nativeElement.files;
      for (const file of files) {
        formData.append('file', file);
        formData.append('publicacion_id', this.multimediaSettings.publicacion_id);
        formData.append('tipo_publicacion', this.multimediaSettings.tipo_publicacion);
        formData.append('tipo_multimedia', this.multimediaSettings.tipo_multimedia);
        formData.append('titulo', this.multimediaGroup.value.titulo);
        this.multimediaService.store(formData)
          .subscribe((res: any) => {
            this.spinner.hide('spinner');
            this.switch.emit(null);
          }, () => {
            this.spinner.hide('spinner');
          });
      }
    }
  }

  updateResults(results: Youtube[]): void {
    this.results = results;
    if (this.results.length === 0) {
      this.message = 'No se encontraron coincidencias...';
    } else {
      this.message = 'Top resultados:';
    }
  }

  seleccionarVideo(event) {
    this.youtubeSearch = false;
    this.spinner.show();
    const data = {
      publicacion_id: this.multimediaSettings.publicacion_id,
      tipo_publicacion: this.multimediaSettings.tipo_publicacion,
      tipo_multimedia: 'youtube',
      url: event.videoUrl,
      titulo: event.title
    };
    this.multimediaService.store(data)
      .subscribe((res: any) => {
/*        this.reload();*/
        this.switch.emit(null);
        this.spinner.hide();
      });
  }

  cancel() {
    this.switch.emit(null);
  }
}
