import {Component, OnInit, ViewChild} from '@angular/core';
import {BeneficiarioService} from '../../beneficiario/beneficiario.service';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {faTrashAlt} from '@fortawesome/free-solid-svg-icons';
import {ComunicadoService} from '../../../services/comunicado.service';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {environment} from '../../../../environments/environment.prod';
import * as XLSX from 'xlsx';
import {ToastrService} from 'ngx-toastr';
import {Router} from '@angular/router';

@Component({
  selector: 'app-comunicado-create',
  templateUrl: './comunicado-create.component.html',
  styleUrls: ['./comunicado-create.component.scss']
})
export class ComunicadoCreateComponent implements OnInit {

  @ViewChild('excel', {static: false}) excel: any;

  faTrashAlt = faTrashAlt;
  comunicadoGroup: FormGroup;
  beneficiarios: any[] = [];
  lista: any[] = [];
  base = environment.base;
  loading = false;

  constructor(private beneficiarioService: BeneficiarioService,
              private comunicadoService: ComunicadoService,
              private router: Router,
              private toastr: ToastrService,
              private http: HttpClient,
              private fb: FormBuilder) {
  }

  ngOnInit() {
    this.beneficiarioService.listadoBeneficiarios()
      .subscribe((listado: any[]) => {
        this.beneficiarios = listado;
      });
    this.comunicadoGroup = this.fb.group({
      contenido: new FormControl(null, [Validators.required]),
      con_sms: new FormControl(false),
      tipo: new FormControl('beneficiario'),
      tipo_seleccion: new FormControl('importacion')
    });
  }

  agregar(event) {
    this.lista.push(event);
    this.lista.sort((a, b) => {
      if (a.nombre_completo > b.nombre_completo) {
        return 1;
      } else {
        return -1;
      }
    });
  }

  quitar(beneficiario: any) {
    this.lista = this.lista.filter((item) => {
      return item.child_number !== beneficiario.child_number;
    });
  }

  enviar() {
    const data = {
      contenido: this.comunicadoGroup.get('contenido').value,
      con_sms: this.comunicadoGroup.get('con_sms').value,
      tipo: this.comunicadoGroup.get('tipo').value,
      lista: this.lista
    };
    if (data.con_sms) {
      const headers = new HttpHeaders({
        'Content-Type': 'application/json',
        Project: 'OaIHEmYEqE0cnK/QHUY3+A==',
        Service: '0cHDytHXwBnmukoX7ln2Ww==',
        Api: 'SZRoeC1MsxTm8jyaqzrZxA=='
      });

      this.lista.map((item: any) => {
        if (item.celular_tutor !== null && item.celular_tutor !== '0' && item.celular_tutor !== '') {
          this.http.post(`https://services.achocallaromero.com:49891/tinkuly/sendsms/kunan`, {
            number: `${item.numero_referencia_familiar}`,
            sms: data.contenido,
            countrycode: '591',
            fullnumber: `591${item.numero_referencia_familiar}`
          }, {
            headers
          }).subscribe((respuesta: any) => {
            console.log(respuesta);
          });
        } else {
          if (item.celular_madre !== null && item.celular_madre !== '0' && item.celular_madre !== '') {
            this.http.post(`https://services.achocallaromero.com:49891/tinkuly/sendsms/kunan`, {
              number: `${item.celular_tutor}`,
              sms: data.contenido,
              countrycode: '591',
              fullnumber: `591${item.celular_tutor}`
            }, {
              headers
            }).subscribe((respuesta: any) => {
              console.log(respuesta);
            });
          } else {
            if (item.celular_padre !== null && item.celular_padre !== '0' && item.celular_padre !== '') {
              this.http.post(`https://services.achocallaromero.com:49891/tinkuly/sendsms/kunan`, {
                number: `${item.celular_padre}`,
                sms: data.contenido,
                countrycode: '591',
                fullnumber: `591${item.celular_padre}`
              }, {
                headers
              }).subscribe((respuesta: any) => {
                console.log(respuesta);
              });
            } else {
              if (item.numero_referencia_familiar !== null && item.numero_referencia_familiar !== '0' && item.numero_referencia_familiar !== '') {
                this.http.post(`https://services.achocallaromero.com:49891/tinkuly/sendsms/kunan`, {
                  number: `${item.celular_madre}`,
                  sms: data.contenido,
                  countrycode: '591',
                  fullnumber: `591${item.celular_madre}`
                }, {
                  headers
                }).subscribe((respuesta: any) => {
                  console.log(respuesta);
                });
              } else {
                if (item.celular_afiliado !== null && item.celular_afiliado !== '0' && item.celular_afiliado !== '') {
                  this.http.post(`https://services.achocallaromero.com:49891/tinkuly/sendsms/kunan`, {
                    number: `${item.celular_afiliado}`,
                    sms: data.contenido,
                    countrycode: '591',
                    fullnumber: `591${item.celular_afiliado}`
                  }, {
                    headers
                  }).subscribe((respuesta: any) => {
                    console.log(respuesta);
                  });
                }
              }
            }
          }
        }
      });
    }
    this.comunicadoService.importacionBeneficiarios(data)
      .subscribe((res: any) => {
        if (res.response) {
          this.toastr.success(res.message, 'Envío Exitoso');
          this.router.navigate(['/admin/comunicacion']);
        }
      });
  }

  upload() {
    const file = this.excel.nativeElement.files[0];
    if (file) {
      if (file.type !== 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' &&
        file.type !== 'application/vnd.ms-excel') {
        this.toastr.error('El archivo no es una hoja de cálculo', 'Excel no encontrado');
      } else {
        this.loading = true;
        const reader: FileReader = new FileReader();
        reader.onload = (e: any) => {
          /*
          * Read workbook
          * */
          const bstr = e.target.result;
          const wb: XLSX.WorkBook = XLSX.read(bstr, {
            type: 'binary'
          });

          /*
          * Grab first sheet
          * */
          const wsname: string = wb.SheetNames[0];
          const ws: XLSX.WorkSheet = wb.Sheets[wsname];

          /*
          * Save data
          * */
          const data: any = XLSX.utils.sheet_to_json(ws, {
            header: 1
          });
          console.log(data);

          let info = '[';
          //this.nuevasCorrespondencias = data.length - 1;
          for (let i = 1; i < data.length; i++) {
            info += '{';
            for (let j = 0; j < data[0].length; j++) {
              const key = data[0][j].toLowerCase().replace(/ /gi, '_');
              info += '"' + key + '":"' + data[i][j] + '"';
              if (j !== (data[0].length - 1)) {
                info += ',';
              }
            }
            info += '}';
            if (i !== data.length - 1) {
              info += ',';
            }
          }
          info += ']';
          /*          console.log(info);*/
          const datosImportacion = JSON.parse(info);
          console.log(datosImportacion);
          if (this.comunicadoGroup.get('tipo').value === 'beneficiario' &&
            this.comunicadoGroup.get('tipo_seleccion').value === 'importacion') {
            this.beneficiarioService.recuperarBeneficiarios(datosImportacion)
              .subscribe((response: any[]) => {
                this.lista = this.lista.concat(response);
                this.lista.sort((a, b) => {
                  if (a.nombre_completo > b.nombre_completo) {
                    return 1;
                  } else {
                    return -1;
                  }
                });
              });
          }

        };
        reader.readAsBinaryString(file);
      }
    }
  }

}
