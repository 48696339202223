import {Component, OnInit} from '@angular/core';
import * as $ from 'jquery';
import {Router} from '@angular/router';
import {faFacebookF, faGoogle, faInstagram, faTiktok, faTwitter, faWhatsapp, faYoutube} from '@fortawesome/free-brands-svg-icons';
import {faChevronUp, faUser, faEnvelope} from '@fortawesome/free-solid-svg-icons';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-publico',
  templateUrl: './publico.component.html',
  styleUrls: ['./publico.component.scss']
})
export class PublicoComponent implements OnInit {

  faGoogle = faGoogle;
  faFacebookF = faFacebookF;
  faWhatsapp = faWhatsapp;
  faTwitter = faTwitter;
  faYoutube = faYoutube;
  faChevronUp = faChevronUp;
  faInstagram = faInstagram;
  faTikTok = faTiktok;
  faUser = faUser;
  faEnvelope = faEnvelope;

  constructor(public router: Router) {
  }

  ngOnInit(): void {
    $(document).ready(() => {
      $('#toggle').click(() => {
        $('#collapsibleNavId').toggle('fast');
      });
    });
  }

  top() {
    $(document).ready(() => {
      $('html, body').animate({
        scrollTop: 0
      }, 'fast');
    });
  }
}
