import { Component, OnInit } from '@angular/core';
import * as $ from 'jquery';

@Component({
  selector: 'app-mqmc',
  templateUrl: './mqmc.component.html',
  styleUrls: ['./mqmc.component.scss']
})
export class MqmcComponent implements OnInit {

    images = [
        {
            src: '../../../assets/img/landing_page/mqmc/MQMC-1.jpg',
            title: 'titulo',
            description: 'descripcion'
        },
        {
            src: '../../../assets/img/landing_page/mqmc/MQMC-2.jpg',
            title: 'titulo',
            description: 'descripcion'
        },
        {
            src: '../../../assets/img/landing_page/mqmc/MQMC-3.jpg',
            title: 'titulo',
            description: 'descripcion'
        },
        {
            src: '../../../assets/img/landing_page/mqmc/MQMC-4.jpg',
            title: 'titulo',
            description: 'descripcion'
        },
        {
            src: '../../../assets/img/landing_page/mqmc/MQMC-5.jpg',
            title: 'titulo',
            description: 'descripcion'
        },
        {
            src: '../../../assets/img/landing_page/mqmc/MQMC-6.jpg',
            title: 'titulo',
            description: 'descripcion'
        }
    ];
  constructor() { }

  ngOnInit() {
    $(document).ready(() => {
      $('html, body').animate({
        scrollTop: 0
      }, 'fast');
    });
  }

}
