import {Component, OnInit} from '@angular/core';
import * as $ from 'jquery';
@Component({
  selector: 'app-patrocinio',
  templateUrl: './patrocinio.component.html',
  styleUrls: ['./patrocinio.component.scss']
})
export class PatrocinioComponent implements OnInit {

  images = [
    {
      src: '../../../assets/img/landing_page/patrocinio/PATRO-1.jpg',
      title: 'titulo',
      description: 'descripcion'
    },
    {
      src: '../../../assets/img/landing_page/patrocinio/PATRO-2.jpg',
      title: 'titulo',
      description: 'descripcion'
    },
    {
      src: '../../../assets/img/landing_page/patrocinio/PATRO-3.jpg',
      title: 'titulo',
      description: 'descripcion'
    },
    {
      src: '../../../assets/img/landing_page/patrocinio/PATRO-4.jpg',
      title: 'titulo',
      description: 'descripcion'
    },
    {
      src: '../../../assets/img/landing_page/patrocinio/PATRO-5.jpg',
      title: 'titulo',
      description: 'descripcion'
    },
    {
      src: '../../../assets/img/landing_page/patrocinio/PATRO-6.jpg',
      title: 'titulo',
      description: 'descripcion'
    },
    {
      src: '../../../assets/img/landing_page/patrocinio/PATRO-7.jpg',
      title: 'titulo',
      description: 'descripcion'
    },
    {
      src: '../../../assets/img/landing_page/patrocinio/PATRO-8.jpg',
      title: 'titulo',
      description: 'descripcion'
    },
  ];

  constructor() {
  }

  ngOnInit() {
    $(document).ready(() => {
      $('html, body').animate({
        scrollTop: 0
      }, 'fast');
    });
  }

}
