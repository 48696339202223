import {Injectable} from '@angular/core';
import {environment} from '../../../environments/environment.prod';
import {HttpClient} from '@angular/common/http';

const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx';

@Injectable({
  providedIn: 'root'
})
export class RevisionService {

  base = environment.base;

  constructor(private http: HttpClient) {
  }

  index() {
    return this.http.get(`${this.base}revisiones`);
  }

  revisionesBeneficiario(beneficiario_id) {
    return this.http.get(`${this.base}revisiones-beneficiario/${beneficiario_id}`);
  }

}
