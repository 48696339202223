import {Component, OnInit, ViewChild} from '@angular/core';
import {environment} from '../../../../environments/environment.prod';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {CapacitacionService} from '../../../services/capacitacion.service';
import {ExcelService} from '../../../services/excel.service';
import {IntegranteService} from '../../../services/integrante.service';
import {NgxSpinnerService} from 'ngx-spinner';
import * as FileSaver from 'file-saver';
import {
  faBackward,
  faDownload,
  faEdit,
  faEye,
  faFastBackward,
  faFastForward,
  faFileExcel,
  faFilePdf,
  faForward,
  faPlus,
  faSearch,
  faTrash,
  faUser
} from '@fortawesome/free-solid-svg-icons';
import {LoteService} from '../../../services/lote.service';
import {TipoCorrespondenciaService} from '../../../services/tipo-correspondencia.service';
import {TipoCorrespondencia} from '../../../models/tipo-correspondencia';
import {Lote} from '../../../models/lote';
import {CorrespondenciaService} from '../../../services/correspondencia.service';
import {AuthService} from '../../../auth.service';
import {Correspondencia} from '../../../models/correspondencia';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {RevisionModalComponent} from '../../../shared/revision-modal/revision-modal.component';
import {ChartConfig} from '../../../models/chart-config';
import * as Chart from 'node_modules/chart.js';
import {HttpClient} from '@angular/common/http';
import {ToastrService} from 'ngx-toastr';
import {ViewerComponent} from '../../../shared/viewer/viewer.component';
import {BeneficiarioModalComponent} from '../../../shared/beneficiario-modal/beneficiario-modal.component';
import {Beneficiario} from '../../../models/beneficiario';

@Component({
  selector: 'app-correspondencia-index',
  templateUrl: './correspondencia-index.component.html',
  styleUrls: ['./correspondencia-index.component.scss']
})
export class CorrespondenciaIndexComponent implements OnInit {

  faSearch = faSearch;
  faEdit = faEdit;
  faTrash = faTrash;
  faPlus = faPlus;
  faFastBackward = faFastBackward;
  faBackward = faBackward;
  faForward = faForward;
  faFastForward = faFastForward;
  faFileExcel = faFileExcel;
  faDownload = faDownload;
  faEye = faEye;
  faUser = faUser;
  faFilePdf = faFilePdf;


  base = environment.base;
  items = 10;
  tipoCorrespondencias: TipoCorrespondencia[] = [];
  lotes: Lote[] = [];
  loteSeleccionado: Lote = null;
  spinnerSettings = environment.spinnerSettings;
  color = '#16874F';
  colorSecondary = '#c7e3d6';
  estado = 'sin_revisar';
  correspondenciaGroup: FormGroup;
  usuario: any = null;

  gestiones: any[] = [];
  meses: any[] = [];

  stats: any = null;

  @ViewChild('files', {static: false}) files: any;
  @ViewChild('files_escaneados', {static: false}) files_escaneados: any;
  @ViewChild('slips', {static: false}) slips: any;

  constructor(private capacitacionService: CapacitacionService,
              private tipoCorrespondenciaService: TipoCorrespondenciaService,
              private loteService: LoteService,
              private correspondenciaService: CorrespondenciaService,
              private modalService: NgbModal,
              private fb: FormBuilder,
              private http: HttpClient,
              private authService: AuthService,
              private excelService: ExcelService,
              private integranteService: IntegranteService,
              private toastr: ToastrService,
              private spinner: NgxSpinnerService) {
  }

  ngOnInit() {
    this.authService.getUser()
      .subscribe((usuario: any) => {
        this.usuario = usuario.success;
      });
    const meses = ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio',
      'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'];
    for (let i = 2019; i <= new Date().getFullYear(); i++) {
      this.gestiones.unshift({
        gestion: i
      });
    }
    meses.map((mes: string, index: number) => {
      this.meses.push({
        mes,
        digito: index + 1
      });
    });
    this.correspondenciaGroup = this.fb.group({
      gestion: new FormControl(new Date().getFullYear()),
      mes: new FormControl(new Date().getMonth() + 1),
      tipo_correspondencia_id: new FormControl(null, [Validators.required]),
      lote_id: new FormControl(null, [Validators.required]),
      mail_control_slip: new FormControl('')
    });
    this.spinner.show('spinner', this.spinnerSettings);
    this.tipoCorrespondenciaService.index()
      .subscribe((response: any) => {
        this.tipoCorrespondencias = response;
        this.spinner.hide('spinner');
      });
  }

  generateChart(id: string, config: ChartConfig) {
    const chart = new Chart(id, config);
    console.log(chart);
  }

  refresh() {
    this.correspondenciaGroup.patchValue({
      tipo_correspondencia_id: null,
      lote_id: null
    });
    this.lotes = [];
    this.loteSeleccionado = null;
  }

  getLotesPeriodoTipoCorrespondencia() {
    this.loteService.lotesPeriodoTipoCorrespondencia(this.correspondenciaGroup.value)
      .subscribe((response: any) => {
        this.lotes = response;
      });
  }

  getEstadisticos(lote_id: number) {
    this.correspondenciaService.estadisticos(lote_id)
      .subscribe((stats: any) => {
        this.stats = stats;
        this.generateChart('tecnicoEstadistico', new ChartConfig(
          'bar',
          {
            labels: ['Total', 'Sin Revisar', 'Enviado', 'Observado'],
            datasets: [{
              axis: 'y',
              backgroundColor: [
                'rgba(255, 99, 132, 0.2)',
                'rgba(255, 159, 64, 0.2)',
                'rgba(255, 205, 86, 0.2)',
                'rgba(75, 192, 192, 0.2)'],
              borderColor: [
                'rgb(255, 99, 132)',
                'rgb(255, 159, 64)',
                'rgb(255, 205, 86)',
                'rgb(75, 192, 192)'
              ],
              borderWidth: 1,
              data: [stats.total, stats.tecnico_sin_revisar, stats.tecnico_enviado, stats.tecnico_observado],
              label: 'Correspondencias de Tecnico'
            }]
          }
        ));
        this.generateChart('supervisorEstadistico', new ChartConfig(
          'bar',
          {
            labels: ['Total', 'Sin Revisar', 'Delegado', 'Validado', 'Devuelto'],
            datasets: [{
              axis: 'y',
              backgroundColor: [
                'rgba(255, 99, 132, 0.2)',
                'rgba(255, 159, 64, 0.2)',
                'rgba(255, 205, 86, 0.2)',
                'rgba(75, 192, 192, 0.2)',
                'rgba(153, 102, 255, 0.2)'
              ],
              borderColor: [
                'rgba(255, 99, 132, 1)',
                'rgba(54, 162, 235, 1)',
                'rgba(255, 206, 86, 1)',
                'rgba(75, 192, 192, 1)',
                'rgba(153, 102, 255, 1)'
              ],
              borderWidth: 1,
              data: [
                stats.total,
                stats.supervisor_sin_revisar,
                stats.supervisor_delegado,
                stats.supervisor_validado,
                stats.supervisor_devuelto
              ],
              label: 'Correspondencias de Supervisor'
            }]
          }
        ));
      });
  }

  correspondenciasLoteSlip() {
    this.spinner.show('spinner', this.spinnerSettings);
    const lote_id = this.correspondenciaGroup.get('lote_id').value;
    const mail_control_slip = this.correspondenciaGroup.get('mail_control_slip').value;
    this.correspondenciaService.correspondenciasLoteSlip(lote_id, mail_control_slip)
      .subscribe((response: Lote) => {
        this.loteSeleccionado = response;
        this.getEstadisticos(response.lote_id);
        this.spinner.hide('spinner');
      });
  }


  /**
   * const fileList: FileList = event.target.files;
   */
  subirArchivos() {
    this.spinner.show('spinner', this.spinnerSettings);
    const fileList = this.files.nativeElement.files;
    const formData: FormData = new FormData();
    const lote_id = this.loteSeleccionado.lote_id;
    const cantidad = fileList.length;
    if (cantidad > 0) {
      for (let i = 0; i < cantidad; i++) {
        formData.append(`files[]`, fileList[i]);
      }
      this.correspondenciaService
        .uploadCorrespondenciasSponsor(formData, lote_id)
        .subscribe((response: any) => {
          this.spinner.hide('spinner');
          this.toastr.success(response.message, 'Correspondencias Subidas exitosamente');
          this.correspondenciasLoteSlip();
        });
    }
  }

  /**
   * const fileList: FileList = event.target.files;
   */
  subirEscaneados() {
    this.spinner.show('spinner', this.spinnerSettings);
    const fileList = this.files_escaneados.nativeElement.files;
    const formData: FormData = new FormData();
    const lote_id = this.loteSeleccionado.lote_id;
    const cantidad = fileList.length;
    if (cantidad > 0) {
      for (let i = 0; i < cantidad; i++) {
        formData.append(`files_escaneados[]`, fileList[i]);
      }
      this.correspondenciaService
        .uploadCorrespondenciasEscaneadas(formData, lote_id)
        .subscribe((response: any) => {
          this.correspondenciasLoteSlip();
          this.spinner.hide('spinner');
          this.toastr.success(response.message, 'Correspondencias Subidas exitosamente');
        });
    }
  }

  /**
   * const fileList: FileList = event.target.files;
   */
  subirSlips() {
    this.spinner.show('spinner', this.spinnerSettings);
    const fileList = this.slips.nativeElement.files;
    const formData: FormData = new FormData();
    const lote_id = this.loteSeleccionado.lote_id;
    const cantidad = fileList.length;
    if (cantidad > 0) {
      for (let i = 0; i < cantidad; i++) {
        formData.append(`slips[]`, fileList[i]);
      }
      this.correspondenciaService
        .uploadSlips(formData, lote_id)
        .subscribe((response: any) => {
          this.spinner.hide('spinner');
          this.toastr.success(response.message, 'Slips Subidos exitosamente');
          this.correspondenciasLoteSlip();
        });
    }
  }

  openRevision(correspondencia: Correspondencia) {
    const modalRef = this.modalService.open(RevisionModalComponent, {
      size: 'xl',
      backdrop: 'static',
      keyboard: false,
    });
    modalRef.componentInstance.correspondencia = correspondencia;
    modalRef.result.then(() => {
      console.log('correspondencias');
      this.getEstadisticos(correspondencia.lote_id);
      this.loteSeleccionado = null;
      this.correspondenciasLoteSlip();
    });
  }

  openViewer(correspondencia: Correspondencia, type: string) {
    const modalRef = this.modalService.open(ViewerComponent, {
      size: 'xl',
      backdrop: 'static',
      keyboard: true,
    });
    modalRef.componentInstance.correspondencia = correspondencia;
    modalRef.componentInstance.type = type;
    modalRef.result.then(() => {
      console.log('correspondencias');
    });
  }

  getColor(correspondencia: Correspondencia) {
    switch (correspondencia.estado_supervisor) {
      case 'delegado':
        return '#809aec';
      case 'sin_revisar':
        return '#9a9a9a';
      case 'validado':
        return '#5ad295';
      case 'devuelto':
        return '#efa8a8';
    }
    switch (correspondencia.estado_tecnico) {
      case 'sin_revisar':
        return '#9a9a9a';
      case 'enviado':
        return '#5ad295';
      case 'observado':
        return '#efa8a8';
    }
  }

  download(): void {
    this.spinner.show('spinner', this.spinnerSettings);
    this.http.get(`${this.base}download-planilla/${this.loteSeleccionado.lote_id}`, {
      responseType: 'blob',
      headers: {}
    }).subscribe(blob => {
      FileSaver.saveAs(blob, `${this.loteSeleccionado.descripcion}.zip`);
      this.spinner.hide('spinner');
    });
  }

  openBeneficiarioModal(beneficiario: Beneficiario) {
    const modalRef = this.modalService.open(BeneficiarioModalComponent, {
      size: 'lg',
      backdrop: 'static'
    });
    modalRef.componentInstance.beneficiario = beneficiario;
  }
}
