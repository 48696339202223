export const environment = {
  production: true,
  /*base: 'http://localhost:8000/api/',*/
  base: 'https://servidor.estrelladelsurbolivia.org/public/api/',
  spinnerSettings: {
    type: 'line-scale-party',
    bdColor: '#FFFFFFe',
    color: '#16874FEE'
  }
};
