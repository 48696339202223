import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {PublicoComponent} from './publico.component';
import {InicioComponent} from './inicio/inicio.component';
import {ContactoComponent} from './contacto/contacto.component';
import {ModelosProgramaticosComponent} from './modelos-programaticos/modelos-programaticos.component';
import {EventoPublicoComponent} from './evento-publico/evento-publico.component';
import {InstitucionComponent} from './institucion/institucion.component';
import {PatrocinioComponent} from './patrocinio/patrocinio.component';
import {CcComponent} from './cc/cc.component';
import {NspComponent} from './nsp/nsp.component';
import {MqmcComponent} from './mqmc/mqmc.component';
import {PactoComponent} from './pacto/pacto.component';
import {NoticiaPublicoComponent} from './noticia-publico/noticia-publico.component';
import {CapacitacionPublicoComponent} from './capacitacion-publico/capacitacion-publico.component';
import {PveComponent} from './pve/pve.component';
import { NuestrosValoresComponent } from './nuestros-valores/nuestros-valores.component';
import { ProyectosPublicoComponent } from './proyectos-publico/proyectos-publico.component';
import { EstructuraRrhhComponent } from './estructura-rrhh/estructura-rrhh.component';
import {ConvocatoriaPublicoComponent} from './convocatoria-publico/convocatoria-publico.component';
import {EstructuraComiteComponent} from './estructura-comite/estructura-comite.component';
import {SembrandoIdeasComponent} from './sembrando-ideas/sembrando-ideas.component';
import {UefaComponent} from './uefa/uefa.component';
import {SemillasEquidadComponent} from './semillas-equidad/semillas-equidad.component';

const routes: Routes = [{
  path: '',
  component: PublicoComponent,
  children: [
    {
      path: 'inicio',
      component: InicioComponent
    },
    {
      path: 'contacto',
      component: ContactoComponent
    },
    {
      path: 'modelos-programaticos',
      component: ModelosProgramaticosComponent
    },
    {
      path: 'eventos',
      component: EventoPublicoComponent
    },
    {
      path: 'institucion',
      component: InstitucionComponent
    },
    {
      path: 'nuestros-valores',
      component: NuestrosValoresComponent
    },
    {
      path: 'proyectos-publico',
      component: ProyectosPublicoComponent
    },
    {
      path: 'estructura-rrhh',
      component: EstructuraRrhhComponent
    },
    {
      path: 'estructura-comite',
      component: EstructuraComiteComponent
    },
    {
      path: 'patrocinio',
      component: PatrocinioComponent
    },
    {
      path: 'juntos-en-tu-desarrollo',
      component: CcComponent
    },
    {
      path: 'ninez-segura',
      component: NspComponent
    },
    {
      path: 'tomando-mis-decisiones',
      component: MqmcComponent
    },
    {
      path: 'participacion-activa-y-emprendedurismo-juvenil',
      component: PactoComponent
    },
    {
      path: 'prevencion-violencia-escuela',
      component: PveComponent
    },
    {
      path: 'sembrando-ideas',
      component: SembrandoIdeasComponent
    },
    {
      path: 'jugar-para-prevenir',
      component: UefaComponent
    },
    {
      path: 'semillas-equidad',
      component: SemillasEquidadComponent
    },
    {
      path: 'noticias',
      component: NoticiaPublicoComponent
    },
    {
      path: 'convocatorias',
      component: ConvocatoriaPublicoComponent
    },
    {
      path: 'capacitaciones',
      component: CapacitacionPublicoComponent
    },
    {
      path: '',
      redirectTo: 'inicio',
      pathMatch: 'full'
    }
  ]
}];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class PublicoRoutingModule {
}
