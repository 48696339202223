import {Component, OnInit} from '@angular/core';
import {faCalendarAlt, faEye, faEyeSlash, faSchool} from '@fortawesome/free-solid-svg-icons';
import {Capacitacion} from '../../models/capacitacion';
import {CapacitacionService} from '../../services/capacitacion.service';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {ToastrService} from 'ngx-toastr';
import {InscripcionModalComponent} from '../../shared/inscripcion-modal/inscripcion-modal.component';

@Component({
  selector: 'app-capacitacion-publico',
  templateUrl: './capacitacion-publico.component.html',
  styleUrls: ['./capacitacion-publico.component.scss']
})
export class CapacitacionPublicoComponent implements OnInit {

  faCalendarAlt = faCalendarAlt;
  faEye = faEye;
  faEyeSlash = faEyeSlash;
  faSchool = faSchool;

  capacitaciones: Capacitacion[] = null;

  constructor(private capacitacionService: CapacitacionService,
              private modalService: NgbModal,
              private toastr: ToastrService) {
  }

  ngOnInit() {
    this.capacitacionService.capacitacionesVisibles()
      .subscribe((response: Capacitacion[]) => {
        this.capacitaciones = response;
      });
  }

  openInscripcion(capacitacion: Capacitacion) {
    const modalRef = this.modalService.open(InscripcionModalComponent, {
      size: 'lg',
      backdrop: 'static',
      keyboard: false,
    });
    modalRef.componentInstance.capacitacion = capacitacion;
  }

}
