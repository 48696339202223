import { Injectable } from '@angular/core';
import {environment} from '../../environments/environment.prod';
import {HttpClient} from '@angular/common/http';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx';

@Injectable({
  providedIn: 'root'
})
export class TestService {

  base = environment.base;
  constructor(private http: HttpClient) { }
  get(documentacionEnLineaId: number) {
    return this.http.get(`${this.base}tests-documentacion-en-linea/${documentacionEnLineaId}`);
  }
  respuestasTest(testId: number) {
    return this.http.get(`${this.base}respuestas-test/${testId}`);
  }
  stats(testId: number) {
    return this.http.get(`${this.base}stats/${testId}`);
  }
  store(data: any) {
    return this.http.post(`${this.base}tests`, data);
  }
  destroy(testId: number) {
    return this.http.delete(`${this.base}tests/${testId}`);
  }

  public exportAsExcelFile(json: any[], excelFileName: string): void {
    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json);
    const workbook: XLSX.WorkBook = { Sheets: { data: worksheet }, SheetNames: ['data'] };
    const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    this.saveAsExcelFile(excelBuffer, excelFileName);
  }
  private saveAsExcelFile(buffer: any, fileName: string): void {
    const data: Blob = new Blob([buffer], {type: EXCEL_TYPE});
    FileSaver.saveAs(data, fileName + '_export_' + new  Date().getTime() + EXCEL_EXTENSION);
  }
}
