import {Component, Input, OnInit} from '@angular/core';
import {Correspondencia} from '../../models/correspondencia';
import {FormControl, Validators} from '@angular/forms';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {CorrespondenciaService} from '../../services/correspondencia.service';
import {DomSanitizer} from '@angular/platform-browser';

@Component({
  selector: 'app-viewer',
  templateUrl: './viewer.component.html',
  styleUrls: ['./viewer.component.scss']
})
export class ViewerComponent implements OnInit {

  @Input() correspondencia: Correspondencia;
  @Input() type: string;
  pdf: any;
  estadoSupervisorControl: FormControl = new FormControl(null, [Validators.required]);

  constructor(public activeModal: NgbActiveModal,
              private sanitizer: DomSanitizer,
              public correspondenciaService: CorrespondenciaService) {
  }

  ngOnInit() {
    switch (this.type) {
      case 'original':
        this.pdf = this.sanitizer.bypassSecurityTrustResourceUrl(this.correspondencia.correspondencia_original);
        break;
      case 'traducido':
        this.pdf = this.sanitizer.bypassSecurityTrustResourceUrl(this.correspondencia.correspondencia_traducida);
        break;
      case 'escaneado':
        this.pdf = this.sanitizer.bypassSecurityTrustResourceUrl(this.correspondencia.correspondencia_escaneada);
        break;
    }
  }

  closeModal() {
    this.activeModal.close();
  }

}
