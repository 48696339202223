/*export class Beneficiario {
  constructor(
    public usuario_id: number,
    public child_number: string,
    public community_id: string,
    public nombre_completo: string,
    public fecha_nacimiento: string,
    public sexo: string,
    public numero_caso: string,
    public token_fcm: string,
    public nombre_tutor: string,
    public telefono_celular_tutor: string,
    public telefono_celular: string,
    public comunitario: string,
    public direccion: string,
    public unidad_educativa_universidad: string,
    public curso: string,
    public latitud: string,
    public longitud: string,
    public foto: string,
    public foto_domicilio: string,
    public revisado: string,
    public edad?: number,
    public cuenta?: string,
    public socio_local?: Zona
) {
  }
}*/

export class Beneficiario {
  constructor(
    public usuario_id: number,
    public zona_id: string,
    public token_fcm: string,
    public child_number: string,
    public community_id?: string,
    public numero_caso?: string,
    public nombre_completo?: string,
    public genero?: string,
    public fecha_nacimiento?: string,
    public celular_afiliado?: string,
    public ci_afiliado?: string,
    public direccion?: string,
    public referencia_direccion?: string,
    public descripcion_casa?: string,
    public padre?: string,
    public celular_padre?: string,
    public ci_padre?: string,
    public madre?: string,
    public celular_madre?: string,
    public ci_madre?: string,
    public referencia_familiar?: string,
    public numero_referencia_familiar?: string,
    public nombre_tutor?: string,
    public celular_tutor?: string,
    public whatsapp_familia?: string,
    public grado_estudio?: string,
    public unidad_educativa_universidad?: string,
    public curso_grado?: string,
    public paralelo?: string,
    public latitud?: string,
    public longitud?: string,
    public numero_hijos?: string,
    public numero_hijos_afiliados?: string,
    public estado_patrocinio?: string,
    public nombre_patrocinador?: string,
    public contacto_patrocinador?: string,
    public pais?: string,
    public fecha_enrolamiento?: string,
    public fecha_registro?: string,
    public foto?: string,
    public foto_domicilio?: string,
    public comunitario?: string,
    public edad?: number,
    public cuenta?: string,
    public nombre_zona?: string,
  ) {
  }
}
