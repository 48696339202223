import {Injectable} from '@angular/core';
import {environment} from '../../environments/environment.prod';
import {HttpClient, HttpParams} from '@angular/common/http';
import {RequestActividad} from '../models/request-actividad';

@Injectable({
  providedIn: 'root'
})
export class ActividadService {

  base = environment.base;

  constructor(private http: HttpClient) {
  }

  show(id: number) {
    return this.http.get(`${this.base}actividades/${id}`);
  }

  filtrarActividadesPeriodo(request: RequestActividad) {
    const params = new HttpParams()
      .set('valor', request.valor)
      .set('items', request.items.toString())
      .set('modelo_programatico', request.modelo_programatico)
      .set('gestion', request.gestion.toString())
      .set('mes', request.mes.toString())
      .set('page', request.page.toString());
    return this.http.get(`${this.base}filtrar-actividades-periodo`, {
      params
    });
  }

  paginateURL(url, req) {
    // tslint:disable-next-line:max-line-length
    return this.http.get(`${url}&items=${req.items}&modelo_programatico=${req.modelo_programatico}&valor=${req.valor}&gestion=${req.gestion}&mes=${req.mes}`);
  }

  store(data: any) {
    return this.http.post(`${this.base}actividades`, data);
  }

  update(data: any, id: number) {
    return this.http.put(`${this.base}actividades/${id}`, data);
  }
}
