import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {environment} from '../../environments/environment.prod';
import {Capacitacion} from '../models/capacitacion';

@Injectable({
  providedIn: 'root'
})
export class CapacitacionService {

  base = environment.base;

  constructor(private http: HttpClient) {
  }

  paginate(modalidad: string, tipo_capacitacion: string, valor: string, items: number, page: number) {
    const params = new HttpParams()
      .set('modalidad', modalidad.toString())
      .set('tipo_capacitacion', tipo_capacitacion.toString())
      .set('valor', valor.toString())
      .set('items', items.toString())
      .set('page', page.toString());

    return this.http.get(`${this.base}capacitaciones-paginate`, {
      params
    });
  }

  capacitacionesVisibles() {
    return this.http.get(`${this.base}capacitaciones-visibles`);
  }

  store(capacitacion: Capacitacion) {
    return this.http.post(`${this.base}capacitaciones`, capacitacion);
  }
}
