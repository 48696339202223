import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {environment} from '../../environments/environment.prod';

@Injectable({
  providedIn: 'root'
})
export class ZonaService {

  base = environment.base;

  constructor(private http: HttpClient) {
  }

  index() {
    return this.http.get(`${this.base}zonas`);
  }

  filtrar(valor) {
    const params = new HttpParams()
      .set('valor', valor);
    return this.http.get(`${this.base}filtrar-zonas`, {
      params
    });
  }

  show(zona_id: number) {
    return this.http.get(`${this.base}zonas/${zona_id}`);
  }

  store(data) {
    return this.http.post(`${this.base}zonas`, data);
  }

  update(data) {
    return this.http.post(`${this.base}zonas/${data.zona_id}`, data);
  }

  destroy(zona_id) {
    return this.http.delete(`${this.base}zonas/${zona_id}`);
  }

}
