import { Component, OnInit } from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {ToastrService} from 'ngx-toastr';
import {ActivatedRoute, Router} from '@angular/router';
import {DocumentacionService} from '../documentacion.service';
import {NgxSpinnerService} from 'ngx-spinner';
import {AngularEditorConfig} from '@kolkov/angular-editor';
import {environment} from '../../../../environments/environment.prod';

@Component({
    selector: 'app-documentacion-edit',
    templateUrl: './documentacion-edit.component.html',
    styleUrls: ['./documentacion-edit.component.scss']
})
export class DocumentacionEditComponent implements OnInit {
    documentacion: any = null;
    documentacionId: number = null;
    documentacionGroup: FormGroup;
    editorConfig = null;
    spinnerSettings = environment.spinnerSettings;
    constructor(private fb: FormBuilder,
                private toastr: ToastrService,
                private router: Router,
                private route: ActivatedRoute,
                private spinner: NgxSpinnerService,
                private documentacionService: DocumentacionService) {
        this.spinner.show('spinner', this.spinnerSettings);
        this.setEditorConfig();
        this.route.params.subscribe((param: any) => {
            this.documentacionId = param.id;
            this.documentacionService.show(param.id)
                .subscribe((documentacion: any) => {
                    this.documentacion = documentacion;
                    this.createForm(documentacion);
                    this.spinner.hide('spinner');
                });
        });
    }

    setEditorConfig() {
        this.editorConfig = {
            editable: true,
            spellcheck: true,
            height: 'auto',
            minHeight: '500',
            maxHeight: 'auto',
            width: 'auto',
            minWidth: '0',
            translate: 'yes',
            enableToolbar: true,
            showToolbar: true,
            placeholder: 'Enter text here...',
            defaultParagraphSeparator: '',
            defaultFontName: '',
            defaultFontSize: '',
            fonts: [
                {class: 'arial', name: 'Arial'},
                {class: 'times-new-roman', name: 'Times New Roman'},
                {class: 'calibri', name: 'Calibri'},
                {class: 'comic-sans-ms', name: 'Comic Sans MS'}
            ],
            customClasses: [
                {
                    name: 'quote',
                    class: 'quote',
                },
                {
                    name: 'redText',
                    class: 'redText'
                },
                {
                    name: 'titleText',
                    class: 'titleText',
                    tag: 'h1',
                },
            ],
            sanitize: true,
            toolbarPosition: 'top',
        };
    }
    ngOnInit() {
    }

    validation() {
        let counter = 0;
        if (this.documentacionGroup.value.menores_5 === 1 || this.documentacionGroup.value.menores_5 === true) {
            counter++;
        }
        if (this.documentacionGroup.value.entre_6_14 === 1 || this.documentacionGroup.value.entre_6_14 === true) {
            counter++;
        }
        if (this.documentacionGroup.value.entre_15_24 === 1 || this.documentacionGroup.value.entre_15_24 === true) {
            counter++;
        }
        if (this.documentacionGroup.value.me_quiero_me_cuido === 1 || this.documentacionGroup.value.me_quiero_me_cuido === true) {
            counter++;
        }
        if (this.documentacionGroup.value.patrocinio === 1 || this.documentacionGroup.value.patrocinio === true) {
            counter++;
        }
        if (this.documentacionGroup.value.publico === 1 || this.documentacionGroup.value.publico === true) {
            counter++;
        }
        return counter !== 0;
    }

    createForm(documentacion: any) {
        this.documentacionGroup = this.fb.group({
            titulo: new FormControl(this.documentacion.titulo, [Validators.required]),
            contenido: new FormControl(documentacion.contenido, [Validators.required]),
            personal_id: new FormControl(parseInt(documentacion.personal_id, 10), [Validators.required]),
            menores_5: new FormControl(parseInt(documentacion.menores_5, 10)),
            entre_6_14: new FormControl(parseInt(documentacion.entre_6_14, 10)),
            entre_15_24: new FormControl(parseInt(documentacion.entre_15_24, 10)),
            me_quiero_me_cuido: new FormControl(parseInt(documentacion.me_quiero_me_cuido, 10)),
            patrocinio: new FormControl(parseInt(documentacion.patrocinio, 10)),
            publico: new FormControl(parseInt(documentacion.publico, 10)),
        });
    }

    update() {
        if (this.validation() === true) {
            this.spinner.show('spinner', this.spinnerSettings);
            this.documentacionService.update(this.documentacionGroup.value, this.documentacionId)
                .subscribe((res: any) => {
                    this.spinner.hide('spinner');
                    this.toastr.success('Aula en linea actualizada', 'Éxito');
                    this.router.navigate(['/admin/aulas']);
                }, (error: any) => {
                    this.spinner.hide('spinner');
                    this.toastr.error('No se pudo acualizar la información de esta aula', 'Intente nuevamente');
                });
        } else {
            this.toastr.info('Por favor registre al menos un programa', 'Revisar');
        }
    }
}
