import {Injectable} from '@angular/core';
import {environment} from '../../environments/environment.prod';
import {HttpClient, HttpParams} from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class LoteService {

  base = environment.base;

  constructor(private http: HttpClient) {
  }

  lotesTipoCorrespondencia(tipo_correspondencia_id: number) {
    return this.http.get(`${this.base}lotes-tipo-correspondencia/${tipo_correspondencia_id}`);
  }

  lotesPeriodoTipoCorrespondencia(data: any) {
    const params = new HttpParams()
      .set('gestion', data.gestion.toString())
      .set('mes', data.mes.toString())
      .set('tipo_correspondencia_id', data.tipo_correspondencia_id.toString());
    return this.http.get(`${this.base}lotes-periodo-tipo-correspondencia`, {
      params
    });
  }
}
