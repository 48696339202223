import {Component, OnInit} from '@angular/core';
import {Zona} from '../../../models/zona';
import {FormControl} from '@angular/forms';
import {environment} from '../../../../environments/environment.prod';
import {BeneficiarioService} from '../../beneficiario/beneficiario.service';
import {ZonaService} from '../../../services/zona.service';
import {RevisionService} from '../../beneficiario/revision.service';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {ToastrService} from 'ngx-toastr';
import {AuthService} from '../../../auth.service';
import {NgxSpinnerService} from 'ngx-spinner';
import {RequestAfiliado} from '../../../models/request-afiliado';
import {
  faBackward,
  faBell,
  faCalendarCheck,
  faCheck,
  faEdit,
  faFastBackward,
  faFastForward,
  faFileExcel,
  faForward,
  faPlus,
  faSearch,
  faTimes,
  faTrash,
  faUpload,
  faUserFriends,
  faUserPlus
} from '@fortawesome/free-solid-svg-icons';
import {ActividadService} from '../../../services/actividad.service';
import {RequestActividad} from '../../../models/request-actividad';

@Component({
  selector: 'app-actividad-index',
  templateUrl: './actividad-index.component.html',
  styleUrls: ['./actividad-index.component.scss']
})
export class ActividadIndexComponent implements OnInit {
  faPlus = faPlus;
  faUserPlus = faUserPlus;
  faUserFriends = faUserFriends;
  faUpload = faUpload;
  faEdit = faEdit;
  faTrash = faTrash;
  faSearch = faSearch;
  faFileExcel = faFileExcel;
  faCheck = faCheck;
  faTimes = faTimes;
  faCalendarCheck = faCalendarCheck;
  faBell = faBell;

  color = '#16874FEE';
  /*icons of pagination*/
  faBackward = faBackward;
  faFastBackward = faFastBackward;
  faForward = faForward;
  faFastForward = faFastForward;

  gestiones: any[] = [];
  meses: any[] = [];
  gestion = new Date().getFullYear();
  mes = new Date().getMonth() + 1;
  actividades: any = null;
  actividadesPOA: any[] = [];
  beneficiarios: any = null;
  zonas: Zona[];
  zonaIdCtrl: FormControl;
  modeloProgramatico: any = 'patrocinio';
  items = 10;
  valor = '';
  usuario: any = null;
  spinnerSettings = environment.spinnerSettings;

  constructor(private beneficiarioService: BeneficiarioService,
              private zonaService: ZonaService,
              private revisionService: RevisionService,
              private actividadService: ActividadService,
              private modalService: NgbModal,
              private toastr: ToastrService,
              private authService: AuthService,
              private spinner: NgxSpinnerService) {
    environment.spinnerSettings.color = this.color;
    this.spinner.show('spinner', this.spinnerSettings);
    const req = new RequestAfiliado();

    actividadService.filtrarActividadesPeriodo(new RequestActividad())
      .subscribe((res: any) => {
        this.actividades = res;
        this.spinner.hide('spinner');
      });
  }

  ngOnInit() {
    this.spinner.show('spinner', this.spinnerSettings);
    const meses = ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio',
      'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'];
    for (let i = 2019; i <= new Date().getFullYear(); i++) {
      this.gestiones.unshift({
        gestion: i
      });
    }
    meses.map((mes: string, index: number) => {
      this.meses.push({
        mes,
        digito: index + 1
      });
    });
  }

  filtrar() {
    this.spinner.show('spinner', this.spinnerSettings);
    const req = new RequestActividad();
    req.gestion = this.gestion;
    req.mes = this.mes;
    req.modelo_programatico = this.modeloProgramatico;
    req.items = this.items;
    req.valor = this.valor;
    this.actividadService.filtrarActividadesPeriodo(req)
      .subscribe((res: any) => {
        this.actividades = res;
        this.spinner.hide('spinner');
      });
  }

  paginateURL(url: string) {
    this.spinner.show('spinner', this.spinnerSettings);
    const req = new RequestActividad();
    req.gestion = this.gestion;
    req.mes = this.mes;
    req.modelo_programatico = this.modeloProgramatico;
    req.items = this.items;
    req.valor = this.valor;

    this.actividadService.paginateURL(url, req)
      .subscribe((res: any) => {
        this.actividades = res;
        this.spinner.hide('spinner');
      });
  }

  setColor(programa: string) {
    switch (programa) {
      case 'publico':
        this.color = '#16874F';
        environment.spinnerSettings.color = '#16874F';
        break;
      case 'patrocinio':
        this.color = '#C60917';
        environment.spinnerSettings.color = '#C60917';
        break;
      case 'cc':
        this.color = '#FF9000';
        environment.spinnerSettings.color = '#FF9000';
        break;
      case 'mqmc':
        this.color = '#87BF36';
        environment.spinnerSettings.color = '#87BF36';
        break;
      case 'nsp':
        this.color = '#6E2673';
        environment.spinnerSettings.color = '#6E2673';
        break;
      case 'pacto':
        this.color = '#207BBC';
        environment.spinnerSettings.color = '#207BBC';
        break;
    }
  }

  setModeloProgramatico(items: number, modeloProgramatico: 'cc' | 'nsp' | 'mqmc' | 'pacto' | 'patrocinio') {
    this.setColor(modeloProgramatico);
    this.modeloProgramatico = modeloProgramatico;
    this.filtrar();
  }

  /*  export() {
      this.spinner.show();
      this.beneficiarioService.beneficiariosExport()
        .subscribe((res: any) => {
          const data = [];
          res.forEach((record: any) => {
            data.push({
              cuenta: record.cuenta,
              child_number: record.child_number,
              nombre_completo: record.nombre_completo,
              fecha_nacimiento: record.fecha_nacimiento,
              edad: record.edad,
              sexo: record.sexo,
              numero_caso: record.numero_caso === 'undefined' ? '' : record.numero_caso,
              telefono_celular: record.telefono_celular,
              nombre_tutor: record.nombre_tutor,
              telefono_celular_tutor: record.telefono_celular_tutor,
              direccion: record.direccion,
              unidad_educativa_universidad: record.unidad_educativa_universidad,
              curso: record.curso
            });
          });
          this.spinner.hide();
          this.beneficiarioService.exportAsExcelFile(data, 'beneficiarios');
        });
    }*/

}
