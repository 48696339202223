import {Component, ElementRef, EventEmitter, OnInit, Output} from '@angular/core';
import {YoutubeService} from '../youtube.service';
import {Youtube} from '../youtube';
import {fromEvent} from 'rxjs';
import {debounceTime, switchAll, tap, map, filter} from 'rxjs/operators';

@Component({
  selector: 'app-search-box',
  templateUrl: './search-box.component.html',
  styleUrls: ['./search-box.component.scss']
})
export class SearchBoxComponent implements OnInit {

  @Output() loading = new EventEmitter<boolean>();
  @Output() results = new EventEmitter<Youtube[]>();

  constructor(private youtube: YoutubeService, private el: ElementRef) { }

  ngOnInit() {
    // convert the `keyup` event into an observable stream
    fromEvent(this.el.nativeElement, 'keyup').pipe(
      map((e: any) => e.target.value),
      filter(text => text.length > 1),
      debounceTime(500),
      tap(() => this.loading.emit(true)),
      map((query: string) => this.youtube.search(query)),
      switchAll())
      .subscribe((results) => {
          this.loading.emit(false);
          this.results.emit(results);
        },
        err => {
          console.log(err);
          this.loading.emit(false);
        },
        () => {
          this.loading.emit(false);
        }
      );
  }
}
