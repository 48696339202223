export class RequestAfiliado {
  constructor(
    public items: number = 10,
    public zona_id: number = 0,
    public modelo_programatico: 'cc' | 'nsp' | 'mqmc' | 'pacto' | 'patrocinio' = 'patrocinio',
    public valor: string = '',
    public es_comunitario: 0 | 1 = 0,
    public page: number = 1
  ) {
  }
}
