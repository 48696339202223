import { Component, OnInit } from '@angular/core';
import * as $ from 'jquery';

@Component({
  selector: 'app-cc',
  templateUrl: './cc.component.html',
  styleUrls: ['./cc.component.scss']
})
export class CcComponent implements OnInit {

  images = [
    {
      src: '../../../assets/img/landing_page/cc/CC-1.png',
      title: 'titulo',
      description: 'descripcion'
    },
    {
      src: '../../../assets/img/landing_page/cc/CC-2.png',
      title: 'titulo',
      description: 'descripcion'
    },
    {
      src: '../../../assets/img/landing_page/cc/CC-3.png',
      title: 'titulo',
      description: 'descripcion'
    },
    {
      src: '../../../assets/img/landing_page/cc/CC-4.png',
      title: 'titulo',
      description: 'descripcion'
    },
    {
      src: '../../../assets/img/landing_page/cc/CC-5.png',
      title: 'titulo',
      description: 'descripcion'
    },
    {
      src: '../../../assets/img/landing_page/cc/CC-6.png',
      title: 'titulo',
      description: 'descripcion'
    }
  ];
  constructor() { }

  ngOnInit() {
    $(document).ready(() => {
      $('html, body').animate({
        scrollTop: 0
      }, 'fast');
    });
  }

}
