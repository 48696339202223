import { Component, OnInit } from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {environment} from '../../../../environments/environment.prod';
import {ToastrService} from 'ngx-toastr';
import {ActivatedRoute, Router} from '@angular/router';
import {NgxSpinnerService} from 'ngx-spinner';
import {EventoService} from '../evento.service';

@Component({
  selector: 'app-evento-edit',
  templateUrl: './evento-edit.component.html',
  styleUrls: ['./evento-edit.component.scss']
})
export class EventoEditComponent implements OnInit {

  evento: any = null;
  eventoId: number = null;
  eventoGroup: FormGroup;
  editorConfig = null;
  spinnerSettings = environment.spinnerSettings;
  constructor(private fb: FormBuilder,
              private toastr: ToastrService,
              private router: Router,
              private route: ActivatedRoute,
              private spinner: NgxSpinnerService,
              private eventoService: EventoService) {
    this.spinner.show('spinner', this.spinnerSettings);
    this.setEditorConfig();
    this.route.params.subscribe((param: any) => {
      this.eventoId = param.id;
      this.eventoService.show(param.id)
        .subscribe((evento: any) => {
          this.evento = evento;
          this.createForm(evento);
          this.spinner.hide('spinner');
        });
    });
  }

  setEditorConfig() {
    this.editorConfig = {
      editable: true,
      spellcheck: true,
      height: 'auto',
      minHeight: '500',
      maxHeight: 'auto',
      width: 'auto',
      minWidth: '0',
      translate: 'yes',
      enableToolbar: true,
      showToolbar: true,
      placeholder: 'Enter text here...',
      defaultParagraphSeparator: '',
      defaultFontName: '',
      defaultFontSize: '',
      fonts: [
        {class: 'arial', name: 'Arial'},
        {class: 'times-new-roman', name: 'Times New Roman'},
        {class: 'calibri', name: 'Calibri'},
        {class: 'comic-sans-ms', name: 'Comic Sans MS'}
      ],
      customClasses: [
        {
          name: 'quote',
          class: 'quote',
        },
        {
          name: 'redText',
          class: 'redText'
        },
        {
          name: 'titleText',
          class: 'titleText',
          tag: 'h1',
        },
      ],
      sanitize: true,
      toolbarPosition: 'top',
    };
  }
  ngOnInit() {
  }

  validation() {
    let counter = 0;
    if (this.eventoGroup.value.menores_5 === 1 || this.eventoGroup.value.menores_5 === true) {
      counter++;
    }
    if (this.eventoGroup.value.entre_6_14 === 1 || this.eventoGroup.value.entre_6_14 === true) {
      counter++;
    }
    if (this.eventoGroup.value.entre_15_24 === 1 || this.eventoGroup.value.entre_15_24 === true) {
      counter++;
    }
    if (this.eventoGroup.value.me_quiero_me_cuido === 1 || this.eventoGroup.value.me_quiero_me_cuido === true) {
      counter++;
    }
    if (this.eventoGroup.value.patrocinio === 1 || this.eventoGroup.value.patrocinio === true) {
      counter++;
    }
    if (this.eventoGroup.value.publico === 1 || this.eventoGroup.value.publico === true) {
      counter++;
    }
    return counter !== 0;
  }

  createForm(evento: any) {
    this.eventoGroup = this.fb.group({
      titulo: new FormControl(evento.titulo, [Validators.required]),
      contenido: new FormControl(evento.contenido, [Validators.required]),
      facilitadores: new FormControl(evento.facilitadores, [Validators.required]),
      fecha_limite_enrolamiento: new FormControl(evento.fecha_limite_enrolamiento, [Validators.required]),
      fecha_inicio: new FormControl(evento.fecha_inicio, [Validators.required]),
      fecha_fin: new FormControl(evento.fecha_fin, [Validators.required]),
      personal_id: new FormControl(parseInt(evento.personal_id, 10), [Validators.required]),
      menores_5: new FormControl(parseInt(evento.menores_5, 10)),
      entre_6_14: new FormControl(parseInt(evento.entre_6_14, 10)),
      entre_15_24: new FormControl(parseInt(evento.entre_15_24, 10)),
      me_quiero_me_cuido: new FormControl(parseInt(evento.me_quiero_me_cuido, 10)),
      patrocinio: new FormControl(parseInt(evento.patrocinio, 10)),
      publico: new FormControl(parseInt(evento.publico, 10))
    });
  }

  update() {
    if (this.validation() === true) {
      this.spinner.show('spinner', this.spinnerSettings);
      this.eventoService.update(this.eventoGroup.value, this.eventoId)
        .subscribe((res: any) => {
          this.spinner.hide('spinner');
          this.toastr.success('Evento actualizado', 'Exito');
          this.router.navigate(['/admin/eventos']);
        }, (error: any) => {
          this.spinner.hide('spinner');
          this.toastr.error('Se recomienda quitar algunas imágenes y agregarlas por separado', 'Contenido muy largo');
        });
    } else {
      this.toastr.info('Por favor registre al menos un programa', 'Revisar');
    }
  }
}
