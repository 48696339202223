import {Component, ElementRef, OnInit, ViewEncapsulation, AfterViewInit} from '@angular/core';
import * as $ from 'jquery';
import {
  faCalendarAlt, faChild,
  faClinicMedical,
  faEnvelope,
  faEye,
  faEyeSlash,
  faGlobeAmericas, faHome, faPeopleCarry, faSchool, faUserFriends,
  faUsers, faUserTie
} from '@fortawesome/free-solid-svg-icons';
import {NoticiaService} from '../../admin/noticia/noticia.service';
import {EventoService} from '../../admin/evento/evento.service';
import { CountUp } from 'countup.js';


@Component({
    selector: 'app-inicio',
    templateUrl: './inicio.component.html',
    encapsulation: ViewEncapsulation.None,
    styleUrls: ['./inicio.component.scss'],
})
export class InicioComponent implements OnInit, AfterViewInit {

    faGlobeAmericas = faGlobeAmericas;
    faEnvelope = faEnvelope;
    faSchool = faSchool;
    faUsers = faUsers;
    faChild = faChild;
    faUserFriends = faUserFriends;
    faUserTie = faUserTie;
    faHome = faHome;
    faCalendarAlt = faCalendarAlt;
    faEye = faEye;
    faEyeSlash = faEyeSlash;

    carousel = {
        indicators: false
    };

    fecha = new Date();

    noticias = null;
    eventos = null;
    urlYoutube = 'https://www.youtube.com/embed/MC3mXLZB9yA?rel=0&controls=0&showinfo=0&autoplay=1&html5=1&allowfullscreen=true&wmode=transparent&loop=1';
    urlFacebook = 'https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fwww.facebook.com%2FEstrella-del-Sur-106167564135987&tabs=timeline&width=1000&height=1000&small_header=false&adapt_container_width=true&hide_cover=false&show_facepile=true&appId';
    faClinicMedical =  faClinicMedical;

    constructor(private noticiaService: NoticiaService,
                private elementRef: ElementRef,
                private eventoService: EventoService) {
        this.noticiaService.ultimasNoticias()
            .subscribe((res: any) => {
                this.noticias = res.slice(0, 10);
            });
        this.eventoService.ultimosEventos()
            .subscribe((res: any) => {
                this.eventos = res.slice(0, 10);
            });

    }
    ngOnInit() {
        $(document).ready(() => {
            $('html, body').animate({
                scrollTop: 0
            }, 'fast');
        });
        const childCountElement1 = this.elementRef.nativeElement.querySelector('#counter1');
        const targetNumber1 = 25000;
        const childCountElement2 = this.elementRef.nativeElement.querySelector('#counter2');
        const targetNumber2 = 3500;
        const childCountElement3 = this.elementRef.nativeElement.querySelector('#counter3');
        const targetNumber3 = 35;
        const childCountElement4 = this.elementRef.nativeElement.querySelector('#counter4');
        const targetNumber4 = 33;
        const childCountElement5 = this.elementRef.nativeElement.querySelector('#counter5');
        const targetNumber5 = 133;
      const childCountElement6 = this.elementRef.nativeElement.querySelector('#counter6');
      const targetNumber6 = 15;

        const countUp1 = new CountUp(childCountElement1, targetNumber1);
        const countUp2 = new CountUp(childCountElement2, targetNumber2);
        const countUp3 = new CountUp(childCountElement3, targetNumber3);
        const countUp4 = new CountUp(childCountElement4, targetNumber4);
        const countUp5 = new CountUp(childCountElement5, targetNumber5);
        const countUp6 = new CountUp(childCountElement6, targetNumber6);
        countUp1.start();
        countUp2.start();
        countUp3.start();
        countUp4.count(1000);
        countUp5.count(1000);
        countUp6.count(1000);
    }
    ngAfterViewInit() {
    const carousel = document.querySelector('.alianzas-carousel');
    const children = Array.from(carousel.children);
    children.forEach(child => {
        const clone = child.cloneNode(true);
        carousel.appendChild(clone);
      });
    carousel.addEventListener('animationiteration', () => {
        children.forEach(child => {carousel.appendChild(carousel.firstElementChild);
        });
      });
  }
}




