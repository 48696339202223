import { Component, OnInit } from '@angular/core';
import * as $ from 'jquery';
@Component({
  selector: 'app-pve',
  templateUrl: './pve.component.html',
  styleUrls: ['./pve.component.scss']
})
export class PveComponent implements OnInit {

  constructor() { }

  ngOnInit() {
    $(document).ready(() => {
      $('html, body').animate({
        scrollTop: 0
      }, 'fast');
    });
  }

}
