import {Component, OnInit} from '@angular/core';
import {
  faEdit,
  faPlus,
  faSearch,
  faTrash,
  faBackward,
  faForward,
  faFastForward,
  faFastBackward
} from '@fortawesome/free-solid-svg-icons';
import {PersonalService} from '../personal.service';
import {NgxSpinnerService} from 'ngx-spinner';
import {environment} from '../../../../environments/environment.prod';

@Component({
  selector: 'app-personal-index',
  templateUrl: './personal-index.component.html',
  styleUrls: ['./personal-index.component.scss']
})
export class PersonalIndexComponent implements OnInit {

  faSearch = faSearch;
  faEdit = faEdit;
  faTrash = faTrash;
  faPlus = faPlus;
  faBackward = faBackward;
  faForward = faForward;
  faFastForward = faFastForward;
  faFastBackward = faFastBackward;

  items = 25;
  personal: any = null;
  valor = '';
  spinnerSettings = environment.spinnerSettings;
  color = '#16874F';

  constructor(private personalService: PersonalService,
              private spinner: NgxSpinnerService) {
  }

  ngOnInit() {
    this.spinner.show('spinner', this.spinnerSettings);
    this.personalService.paginate(this.items)
      .subscribe((res: any) => {
        this.personal = res;
        this.spinner.hide('spinner');
      });
  }

  paginate() {
    this.spinner.show('spinner', this.spinnerSettings);
    this.personalService.paginate(this.items)
      .subscribe((res: any) => {
        this.personal = res;
        this.spinner.hide('spinner');
      });
  }

  paginateURL(url: string) {
    this.spinner.show('spinner', this.spinnerSettings);
    this.personalService.paginateURL(url)
      .subscribe((res: any) => {
        this.personal = res;
        this.spinner.hide('spinner');
      });
  }

  search() {
    const data = {
      items: this.items,
      valor: this.valor
    };
    this.spinner.show('spinner', this.spinnerSettings);
    this.personalService.search(data)
      .subscribe((res: any) => {
        this.personal = res;
        this.spinner.hide('spinner');
      });
  }

  destroy(personal) {
    const mess = 'Esta seguro de eliminar al personal ' + personal.nombres;
    if (confirm(mess)) {
      this.spinner.show('spinner', this.spinnerSettings);
      this.personalService.destroy(personal.personal_id)
        .subscribe((res: any) => {
          this.personal = res;
          this.paginate();
          this.spinner.hide('spinner');
        });
    }
  }
}
