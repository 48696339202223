import {Component, OnInit} from '@angular/core';
import {
  faBackward,
  faEdit,
  faFastBackward,
  faFastForward,
  faForward,
  faPlus,
  faSearch,
  faTrash,
  faUpload,
  faUserFriends,
  faUserPlus,
  faFile
} from '@fortawesome/free-solid-svg-icons';
import {NgxSpinnerService} from 'ngx-spinner';
import {AuthService} from '../../../auth.service';
import {environment} from '../../../../environments/environment.prod';
import {BeneficiarioService} from '../../beneficiario/beneficiario.service';
import {faFileExcel} from '@fortawesome/free-solid-svg-icons/faFileExcel';
import {ToastrService} from 'ngx-toastr';
import {FormControl} from '@angular/forms';
import {Zona} from '../../../models/zona';
import {ZonaService} from '../../../services/zona.service';
import {RequestAfiliado} from '../../../models/request-afiliado';

@Component({
  selector: 'app-comunitario-index',
  templateUrl: './comunitario-index.component.html',
  styleUrls: ['./comunitario-index.component.scss']
})
export class ComunitarioIndexComponent implements OnInit {

  faPlus = faPlus;
  faUserPlus = faUserPlus;
  faUserFriends = faUserFriends;
  faUpload = faUpload;
  faEdit = faEdit;
  faFile = faFile;
  faTrash = faTrash;
  faSearch = faSearch;
  faFileExcel = faFileExcel;
  base = environment.base;

  color = '#C60917';
  /*icons of pagination*/
  faBackward = faBackward;
  faFastBackward = faFastBackward;
  faForward = faForward;
  faFastForward = faFastForward;

  comunitarios: any = null;
  modeloProgramatico: any = 'patrocinio';
  items = 10;
  valor = '';
  zonas: Zona[];
  spinnerSettings = environment.spinnerSettings;

  zonaIdCtrl: FormControl;
  usuario: any = null;

  constructor(private comunitarioService: BeneficiarioService,
              private zonaService: ZonaService,
              private authService: AuthService,
              private toastr: ToastrService,
              private spinner: NgxSpinnerService) {
    environment.spinnerSettings.color = '#C60917';
    this.spinner.show('spinner', this.spinnerSettings);
    const req = new RequestAfiliado();
    req.es_comunitario = 1;
    comunitarioService.buscarAfiliados(req)
      .subscribe((res: any) => {
        this.comunitarios = res;
        this.spinner.hide('spinner');
      });
  }

  ngOnInit() {
    this.spinner.show('spinner', this.spinnerSettings);
    this.zonaIdCtrl = new FormControl(0);
    this.authService.getUser()
      .subscribe((res: any) => {
        this.usuario = res.success;
      });

    this.zonaService.index()
      .subscribe((res: Zona[]) => {
        res.unshift(new Zona('Todos', 'T', null, null, null, 0));
        this.zonas = res;
        this.spinner.hide('spinner');
      });
  }

  buscarAfiliados() {
    this.spinner.show('spinner', this.spinnerSettings);
    const req = new RequestAfiliado();
    req.items = this.items;
    req.zona_id = this.zonaIdCtrl.value;
    req.modelo_programatico = this.modeloProgramatico;
    req.valor = this.valor;
    req.es_comunitario = 1;

    this.comunitarioService.buscarAfiliados(req)
      .subscribe((res: any) => {
        this.comunitarios = res;
        this.spinner.hide('spinner');
      });
  }

  paginateURL(url: string) {
    this.spinner.show('spinner', this.spinnerSettings);
    const req = new RequestAfiliado();
    req.items = this.items;
    req.zona_id = this.zonaIdCtrl.value;
    req.modelo_programatico = this.modeloProgramatico;
    req.valor = this.valor;
    req.es_comunitario = 1;

    this.comunitarioService.paginateURL(url, req)
      .subscribe((res: any) => {
        this.comunitarios = res;
        this.spinner.hide('spinner');
      });
  }


  setColor(programa: string) {
    switch (programa) {
      case 'publico':
        this.color = '#16874F';
        environment.spinnerSettings.color = '#16874F';
        break;
      case 'patrocinio':
        this.color = '#C60917';
        environment.spinnerSettings.color = '#C60917';
        break;
      case 'cc':
        this.color = '#FF9000';
        environment.spinnerSettings.color = '#FF9000';
        break;
      case 'mqmc':
        this.color = '#87BF36';
        environment.spinnerSettings.color = '#87BF36';
        break;
      case 'nsp':
        this.color = '#6E2673';
        environment.spinnerSettings.color = '#6E2673';
        break;
      case 'pacto':
        this.color = '#207BBC';
        environment.spinnerSettings.color = '#207BBC';
        break;
    }
  }

  setModeloProgramatico(items: number, modeloProgramatico: 'cc' | 'nsp' | 'mqmc' | 'pacto' | 'patrocinio') {
    this.setColor(modeloProgramatico);
    this.modeloProgramatico = modeloProgramatico;
    this.buscarAfiliados();
  }


  export() {
    this.spinner.show();
    this.comunitarioService.comunitariosExport()
      .subscribe((res: any) => {
        const data = [];
        res.forEach((record: any) => {
          data.push({
            cuenta: record.cuenta,
            nombre_completo: record.nombre_completo,
            carnet: record.child_number,
            fecha_nacimiento: record.fecha_nacimiento,
            edad: record.edad,
            sexo: record.sexo,
            telefono_celular: record.telefono_celular,
            nombre_tutor: record.nombre_tutor,
            telefono_celular_tutor: record.telefono_celular_tutor,
            direccion: record.direccion,
            unidad_educativa_universidad: record.unidad_educativa_universidad,
            curso: record.curso,
          });
        });
        this.spinner.hide();
        this.comunitarioService.exportAsExcelFile(data, 'comunitarios');
      });
  }

  destroy(beneficiario: any) {
    if (confirm(`¿Esta seguro de eliminar a ${beneficiario.nombre_completo}?`)) {
      this.comunitarioService.destroy(beneficiario.beneficiario_id)
        .subscribe((res: any) => {
          this.toastr.success('El comuntiario fué eliminado', 'Éxito');
        });
    }
  }
}
